import { PayloadAction } from "@reduxjs/toolkit";
import { T_farm } from "../../Requests/farms";

export type T_farmState = {
  activeFarm: T_farm;
  farms: T_farm[];
};

// farms reducers
export const farmReducers = {
  setFarms: {
    reducer: (
      state: T_farmState | null,
      action: PayloadAction<T_farm[]>
    ): T_farmState => {
      var state = { ...state };
      state.farms = action.payload;
      return state;
    },
    prepare: (farms: T_farm[]) => ({
      payload: farms,
    }),
  },
  setActiveFarm: {
    reducer: (
      state: T_farmState | null,
      action: PayloadAction<T_farm>
    ): T_farmState => {
      var state = { ...state };
      state.activeFarm = action.payload;
      return state;
    },
    prepare: (farm: T_farm) => ({
      payload: farm,
    }),
  },
};
