import { Grid, Typography, Box, Radio, RadioGroup, FormControl, FormControlLabel, FormLabel, TextField, Button } from "@mui/material";
import React, { ReactElement, useState, ChangeEvent } from "react";

interface Question1Object {
    id: string;
    question: string;
    answers: string[];
}

interface TemplateQuestion1Props {
    id: string;
    question: string;
    answers: string[];
    onAnswer: (id: string, answer: string) => void;
}

interface Question2Object {
    id: string;
    question: string;
}

interface TemplateQuestion2Props {
    id: string;
    question: string;
    onAnswer: (id: string, answer: string) => void;
}

const TemplateQuestion1 = ({ id, question, answers, onAnswer }: TemplateQuestion1Props): ReactElement => {

    const handleOptionChange = (event: ChangeEvent<HTMLInputElement>) => {
        const answer = event.target.value;
        onAnswer(id, answer);
    };

    return (
        <FormControl className="mt-5 w-100">
            <FormLabel style={{ marginBottom: '10px', color: 'black', fontWeight: 'bold' }}>
                {question}
            </FormLabel>
            <RadioGroup
                aria-label="feedback"
                name={id}
                onChange={handleOptionChange}
            >
                {answers.map((answer, index) => (
                    <FormControlLabel
                        key={index}
                        value={answer}
                        control={<Radio />}
                        label={answer}
                    />
                ))}
            </RadioGroup>
        </FormControl>
    );
}

const TemplateQuestion2 = ({ id, question, onAnswer }: TemplateQuestion2Props): ReactElement => {

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const answer = event.target.value;
        onAnswer(id, answer);
    };

    return (
        <FormControl className="mt-5 " fullWidth>
            <FormLabel style={{ marginBottom: '10px', color: 'black', fontWeight: 'bold' }}>
                {question}
            </FormLabel>
            <TextField
                fullWidth
                multiline
                variant="outlined"
                label="Raspuns"
                onChange={handleInputChange}
            />
        </FormControl>

    );
};


export const FeedBackComponent = (): ReactElement | null => {
    const [answers, setAnswers] = useState<{ [key: string]: string }>({});
    const [error, setError] = useState<string>('');

    const questions: (Question1Object | Question2Object)[] = [
        { id: 'q1', question: 'Cum evaluați experiența generală cu aplicația noastră?', answers: ['Excelentă', 'Bună', 'Satisfăcătoare', 'Nesatisfăcătoare'] },
        { id: 'q2', question: 'Care funcționalități v-au fost cele mai utile și de ce?' },
        { id: 'q3', question: 'Ați întâmpinat dificultăți în utilizarea aplicației? Dacă da, detaliați.' },
        { id: 'q4', question: 'Cât de ușor a fost să învățați să folosiți aplicația?', answers: ['Foarte ușor', 'Ușor', 'Moderată', 'Dificil'] },
        { id: 'q5', question: 'Cât de des utilizați aplicația pentru a urmări activitățile din fermă?', answers: ['Zilinic', 'Săptămânal', 'Lunar', 'Rar'] },
        { id: 'q6', question: 'Ați recomanda aplicația altor fermieri?', answers: ['Da', 'Nu', 'Poate'] },
        { id: 'q7', question: 'Există aspecte ale aplicației pe care le considerați confuze sau dificile?' },
        { id: 'q8', question: 'Cum poate aplicația noastră sprijini mai bine atingerea obiectivelor dvs.?.?' },
    ];

    const handleAnswer = (id: string, answer: string) => {
        setAnswers(prevAnswers => ({ ...prevAnswers, [id]: answer }));
    };

    const handleSubmit = () => {
        const unansweredQuestions = questions.filter(q => !answers[q.id]);
        if (unansweredQuestions.length > 0) {
            setError('Vă rugăm să răspundeți la toate întrebările.');
            return;
        }

        setError('');
        const jsonResponse = JSON.stringify(answers, null, 2);
        console.log('Răspunsuri colectate:', jsonResponse);
    };

    return (
        <div className="d-flex justify-content-center align-items-center" style={{ marginTop: "140px" }}>
            <Box
                sx={{
                    backgroundColor: 'white',
                    width: { md : '60%', xs : '100%'},
                    height: 'auto',
                    padding: '20px'
                }}
            >
                <Grid container direction="column" spacing={3}>
                    <Grid item className="d-flex mb-5" justifyContent="center" alignItems="center">
                        <Typography className="text-black-b mt-5" sx={{ fontSize: { md:"40px", xs : '20px'}, width: '70%', textAlign: 'center' }}>
                            Te rugăm să răspunzi la câteva întrebări pentru a ne ajuta să îmbunătățim serviciile noastre.
                        </Typography>
                    </Grid>
                    <Grid item container className="d-flex" direction={'column'} >
                        <Box sx={{ paddingLeft: '10%', paddingRight: '10%' }}>
                            {questions.map((q: Question1Object | Question2Object, index) => {
                                if ('answers' in q) {
                                    return (
                                        <TemplateQuestion1
                                            key={q.id}
                                            id={q.id}
                                            question={q.question}
                                            answers={q.answers}
                                            onAnswer={handleAnswer}
                                        />
                                    );
                                } else {
                                    return (
                                        <TemplateQuestion2
                                            key={q.id}
                                            id={q.id}
                                            question={q.question}
                                            onAnswer={handleAnswer}
                                        />
                                    );
                                }
                            })}
                        </Box>
                    </Grid>

                    <Grid item  container  direction={'column'} className="d-flex justify-content-cente align-items-center" >
                        {error && <p style={{ color: 'red' }}>{error}</p>}
                        <Button className="button-green" onClick={handleSubmit}>Trimite Răspunsurile</Button>
                    </Grid>
                </Grid>
            </Box>
        </div>
    );
};