// authFLOW

import axios from "axios";
import { jwtDecode } from "jwt-decode";

export interface T_userCredentials {
  username: string;
  password: string;
}

export interface T_user {
  _id?: string;
  state?: boolean;
  username?: string;
  name?: string;
  password?: string;
  birth?: string;
  school?: string;
  certificates?: string;
  experience?: string;
  phone?: string;
  position?: string;
  email?: string;
  roles?: ["farmer"?, "worker"?, "admin"?, "none"?];
}

export const authUser = (
  userCredentials: T_userCredentials,
  cb: (done: boolean) => void
) => {
  axios({
    method: "get",
    params: userCredentials,
    url: `${process.env.server}/api2/auth/login`,
  })
    .then((response) => {
      const token = response.data.token;

      // save token to cookies
      localStorage.setItem("token", token);

      cb(true);
    })
    .catch((e) => {
      cb(false);
      console.error(JSON.stringify(e, null, 1));
    });
};

export const getUserInfo = (): T_user => {
  let user: T_user | null = null;
  const token = localStorage.getItem("token");

  if (token) {
    const data: T_user = jwtDecode(token);
    return data;
  } else {
    return user;
  }
};
