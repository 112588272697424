import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { store } from "../store/main";
import { setMachineries } from "../store/slicers/machinary";
import { setActiveFarm, setFarms } from "../store/slicers/farm";

export const Logout = (): null => {
  const navigate = useNavigate();
  // logout user

  useEffect(() => {
    localStorage.clear();

    // clear redux store
    store.dispatch(setMachineries(null));
    store.dispatch(setFarms(null));
    store.dispatch(setActiveFarm(null));
    navigate("/");
  }, []);

  return null;
};
