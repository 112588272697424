import { Grid, Grow, Popover, Typography } from "@mui/material";
import React, { ReactElement, useEffect, useState } from "react";
import {
  T_Path,
  T_machinery,
  T_trackInfo,
  getMachineryPath,
} from "../Requests/machinery";
import { store } from "../store/main";
import { Buttons, T_button } from "./Buttons";
import moment from "moment";
import { Inputs, T_input } from "./Inputs";

export interface T_Modal {
  open: boolean;
  anchorEl: HTMLButtonElement;
  onClose: () => void;
  actions?: {
    masure: (machinaryId: string) => void;
    closeModal: () => void;
    setModalTarget: (modalTarget: T_Modal["target"]) => void;
    setMachinaryPath: (path: T_Path) => void;
  };
  target: {
    _id?: string;
    type: "marker" | "mesure" | "mesureEdit" | "interval";
    machinaryPath?: T_Path;
    trackInfo?: T_trackInfo;
  };
}

export const Modal = (props: T_Modal): ReactElement | null => {
  const getModalContent = () => {
    switch (props.target?.type) {
      case "marker":
        return <MarkerModal {...props} />;
      case "mesure":
        return <MesureModal {...props} />;
      default:
        return null;
    }
  };

  switch (props.target?.type) {
    case "interval":
      return <IntervalModal {...props} />;
    case "mesureEdit":
      return <MesureEditModal {...props} />;

    default:
      return (
        <Popover
          onClose={props.onClose}
          open={props.open}
          anchorEl={props.anchorEl}
          id="popOver"
        >
          {getModalContent()}
        </Popover>
      );
  }
};

const MesureModal = (props: T_Modal): ReactElement | null => {
  return (
    <Grid container>
      <Grid item className="p-2">
        <Typography fontWeight={"bold"} fontSize={10}>
          {"Alege măcar 3 puncte pe hartă"}
        </Typography>
      </Grid>
    </Grid>
  );
};

const MesureEditModal = (props: T_Modal): ReactElement | null => {
  const [machinary, setMachinary] = useState<T_machinery>();

  useEffect(() => {
    const machinaries = store.getState().machinaries;
    const machinary = machinaries.filter(
      (machinary) => machinary._id === props.target._id
    )[0];
    setMachinary(machinary);
  }, []);

  const trackInfo: T_trackInfo = props.target?.trackInfo;
  const pathInfo: T_Path = props.target?.machinaryPath;
  return (
    <Grid container>
      <Grid
        item
        className="bg-white p-2"
        sx={{
          position: "absolute",
          bottom: 2,
          left: 2,
          minHeight: 50,
          zIndex: 2000,
        }}
      >
        {trackInfo ? (
          <>
            <Typography variant="h5">{`${machinary.name}`}</Typography>
            <Typography>
              {`Distanta totala: ${(pathInfo.distance / 1000).toFixed(
                2
              )} km, Consum total: ${pathInfo.consumption?.toFixed(2)} l`}
            </Typography>
            <Typography>
              {`Suprafață: ${trackInfo.area?.toFixed(
                2
              )} ha, Consum: ${trackInfo.consumption?.toFixed(2)} l`}
            </Typography>
            <Typography>
              {`Timp total: ${trackInfo.totalMinutes?.toFixed(
                0
              )} minute , Timp deplasare: ${trackInfo.workingMinutes?.toFixed(
                2
              )} minute`}
            </Typography>
            <Typography>
              {trackInfo.interval && trackInfo.interval.start
                ? `${moment(trackInfo.interval?.start).format(
                    "DD/MM HH:mm"
                  )} --> , ${moment(trackInfo.interval?.end).format(
                    "DD/MM HH:mm"
                  )}`
                : null}
            </Typography>
            <Buttons
              buttons={[
                {
                  value: "close",
                  label: "Închide",
                  action: () => {
                    props.actions.closeModal();
                  },
                  variant: "contained",
                },
              ]}
            />
          </>
        ) : (
          <Typography>
            {"Alegeți minim 4 puncte pe hartă ca să putem forma un poligon"}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

const MarkerModal = (props: T_Modal): ReactElement | null => {
  const [machinary, setMachinary] = useState<T_machinery>(null);

  useEffect(() => {
    // get current machinary
    const _id = props.target?._id;

    const state = store.getState();
    const machinary = state.machinaries.filter(
      (machinary) => machinary._id === _id
    )[0];
    if (machinary) {
      setMachinary(machinary);
    }
  }, [props.target]);

  return (
    <Grid container>
      <Grid item className="p-2">
        <Typography fontWeight={"bold"} fontSize={15}>
          {machinary?.name}
        </Typography>
        <Typography fontWeight={"bold"} fontSize={10}>
          {`${(props.target?.machinaryPath?.distance / 1000).toFixed(
            2
          )} km ${props.target?.machinaryPath?.consumption.toFixed(2)} l`}
        </Typography>
      </Grid>
      <Grid item xs={12} className="p-2">
        <Buttons
          buttons={[
            {
              value: "masoara",
              label: "Măsoară",
              variant: "contained",
              action: () => {
                props.actions.masure(machinary?._id);
              },
            },
            {
              value: "altInterval",
              label: "Alt interval",
              variant: "contained",
              action: () => {
                props.actions.setMachinaryPath(null);
                props.actions.setModalTarget({
                  type: "interval",
                  _id: props.target._id,
                  machinaryPath: null,
                });
              },
            },
          ]}
        />
      </Grid>
    </Grid>
  );
};

export const IntervalModal = (props: T_Modal): ReactElement | null => {
  const [form, setForm] = useState({
    machineryId: props.target._id,
    start: moment(),
    end: moment(),
  });

  const [machinary, setMachinary] = useState<T_machinery>();

  useEffect(() => {
    const machinaries = store.getState().machinaries;
    const machinary = machinaries.filter(
      (machinary) => machinary._id === form.machineryId
    )[0];
    setMachinary(machinary);
  }, []);

  const showPath = () => {
    getMachineryPath(
      {
        deviceId: machinary?.deviceId,
        start: form.start,
        end: form.end,
      },
      (path) => {
        props.actions.setMachinaryPath(path);
        props.actions.setModalTarget({
          type: "marker",
          _id: machinary?._id,
          machinaryPath: path,
        });
      }
    );
  };

  const inputs: T_input[] = [
    {
      value: "start",
      label: "Start",
      type: "dateTime",
      sx: {
        margin: 2,
      },
    },
    {
      value: "end",
      label: "Final",
      type: "dateTime",
      sx: {
        margin: 2,
      },
    },
  ];

  const buttons: T_button[] = [
    {
      value: "close",
      label: "Închide",
      action: () => props.actions.closeModal(),
      variant: "contained",
    },
    {
      value: "getPath",
      label: "Afișează",
      action: showPath,
      variant: "contained",
    },
  ];

  const handleChange = (target: T_input, value: any) => {
    let f = { ...form };
    Object.assign(f, { [target.value]: value });
    setForm(f);
  };

  return (
    <Grid container>
      <Grid
        item
        className="bg-white p-2"
        sx={{
          position: "absolute",
          bottom: 2,
          left: 2,
          minHeight: 50,
          zIndex: 2000,
        }}
      >
        <Typography>{`${machinary?.name}`}</Typography>
        <Inputs inputs={inputs} onChange={handleChange} values={form} />
        <Buttons buttons={buttons} />
      </Grid>
    </Grid>
  );
};
