import {
  APIProvider,
  AdvancedMarker,
  Map,
  useMap,
} from "@vis.gl/react-google-maps";

import React, {
  DOMElement,
  ReactElement,
  useEffect,
  useState,
  version,
} from "react";
import { T_Modal } from "./Modal";
import { T_Point } from "../Pages/Map";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";

export interface T_MapMarker {
  lat: number;
  lng: number;
  collectTime: string;
  name?: string;
  _id?: string;
  type?: "machinary";
  color?: "red";
}

export interface T_MapData {
  actions?: {
    onClickMarker?: (marker: T_MapMarker, target: HTMLButtonElement) => void;
    getPathInfo?: (polygon: T_Point[]) => void;
  };
  target?: T_Modal["target"];
  markers?: T_MapMarker[];
  polyline?: { lat: number; lng: number; collectTime?: string }[];
}

export const BaseMap = (props: T_MapData): ReactElement | null => {
  return (
    <APIProvider
      apiKey="AIzaSyBxY7QOAGtxQZMuRwQJ9EULKpLT9qIFToU"
      libraries={["core", "maps", "marker"]}
    >
      <BaseMapComp {...props} />
    </APIProvider>
  );
};

export const BaseMapComp = (props: T_MapData): ReactElement | null => {
  const map = useMap();
  const [centerSet, setCenterSet] = useState<Boolean>(false);
  const [polylineOnMap, setPolylineOnMap] = useState<google.maps.Polyline>();

  useEffect(() => {
    if (!map) return;
  }, [map]);

  useEffect(() => {
    if (!props.markers?.length && centerSet) {
      // clear center set
      setCenterSet(false);
    }

    if (map && props.markers?.length && !centerSet) {
      const bounds = new google.maps.LatLngBounds();
      props.markers?.forEach((point) => {
        point ? bounds.extend({ lat: point.lat, lng: point.lng }) : null;
      });
      map.fitBounds(bounds);

      // zoom out 1 step
      map.setZoom(map.getZoom() - 1);
      setCenterSet(true);
    }
  }, [props.markers]);

  useEffect(() => {
    if (!polylineOnMap && props.polyline && map) {
      const polyline = new google.maps.Polyline({
        strokeWeight: 1,
        strokeColor: "red",
        path: props.polyline.map((point) => {
          return { lat: point.lat, lng: point.lng };
        }),
      });

      polyline?.setMap(map);
      setPolylineOnMap(polyline);
    }

    if (polylineOnMap && !props.polyline) {
      // hide polyline

      polylineOnMap.setMap(null);
      setPolylineOnMap(null);
    }
  }, [props.polyline]);

  return (
    <Map
      mapId="mainMap"
      defaultCenter={{ lat: 47.492829, lng: 27.321712 }}
      defaultZoom={10}
      mapTypeId="hybrid"
    >
      {props.markers?.map((marker, index) => {
        let key = `${String(marker.lat)}_${String(marker.lng)}_${marker._id}_${
          marker.collectTime
        }`;
        return marker ? (
          <BaseMarkerComp
            key={key}
            marker={marker}
            actions={{
              onClickMarker: props.actions?.onClickMarker,
            }}
          />
        ) : null;
      })}
      {props.target?.type === "mesureEdit" ? (
        <BaseMapMesure
          map={map}
          target={props.target}
          key={"mesureComp"}
          actions={{
            getPathInfo: props.actions?.getPathInfo,
          }}
        />
      ) : null}
    </Map>
  );
};

export const BaseMarkerComp = (props: {
  marker: T_MapMarker;
  actions?: {
    onClickMarker?: (marker: T_MapMarker, target: HTMLButtonElement) => void;
  };
}): ReactElement | null => {
  let key = `${String(props.marker.lat)}_${String(props.marker.lng)}_${
    props.marker._id
  }_${props.marker.collectTime}`;

  return (
    <>
      <AdvancedMarker
        key={key}
        position={{ lat: props.marker.lat, lng: props.marker.lng }}
        title={props.marker.name}
        onClick={(event: any) => {
          props.actions?.onClickMarker(
            props.marker,
            event.domEvent.currentTarget
          );
        }}
      />
    </>
  );
};

export const BaseMapMesure = (props: {
  map: google.maps.Map;
  target: T_Modal["target"];
  actions: { getPathInfo: (polygon: T_Point[]) => void };
}): ReactElement | null => {
  const [mesureInitiated, setMesureInitiated] = useState(false);

  let points: T_Point[];
  let markerPoints: google.maps.Marker[];
  var polygon: google.maps.Polygon;

  const clearMesurments = () => {
    polygon?.setMap(null);
    markerPoints.forEach((marker) => marker.setMap(null));
    points = [];
  };

  const initiateMesurement = () => {
    const event = google.maps.event;
    (points = []), (markerPoints = []);
    polygon = null;
    if (props.map) {
      points = [];
      markerPoints = [];
      polygon = null;

      props.map.addListener("click", (event: any) => {
        const point: T_Point = {
          lat: event.latLng.lat(),
          lng: event.latLng.lng(),
        }; // create marker
        let marker = new google.maps.Marker({
          position: point,
          icon: {
            path: faLocationDot.icon[4] as string,
            anchor: new google.maps.Point(
              faLocationDot.icon[0] / 2, // width
              faLocationDot.icon[1] // height
            ),
            fillOpacity: 1,
            fillColor: "white",
            scale: 0.08,
          },
          map: props.map,
        });

        markerPoints.push(marker);
        points.push(point);
        checkPoints();
      });
      const checkPoints = () => {
        if (points.length >= 3) {
          // create editable polygon
          if (!polygon) {
            polygon = new google.maps.Polygon({
              paths: points,
              strokeColor: "#FF0000",
              strokeOpacity: 0,
              strokeWeight: 2,
              fillColor: "#FF0000",
              editable: true,
              geodesic: true,
              map: props.map,
            });

            const polygonPath: T_Point[] = polygon
              .getPath()
              .getArray()
              .map((point) => {
                return { lat: point.lat(), lng: point.lng() };
              });
            props.actions?.getPathInfo(polygonPath);

            event.addListener(polygon, "mouseup", () => {
              // move started so hide all
              markerPoints.forEach((marker) => marker.setMap(null));
              event.clearListeners(props.map, "click");
              const polygonPath: T_Point[] = polygon
                .getPath()
                .getArray()
                .map((point) => {
                  return { lat: point.lat(), lng: point.lng() };
                });
              props.actions?.getPathInfo(polygonPath);
              // calculate area for this poligon
            });
          }
          polygon.setPaths(points);
        }
      };
    }
  };

  useEffect(() => {
    // enable mesure on first load

    if (!mesureInitiated) {
      initiateMesurement();
      setMesureInitiated(true);
      return () => clearMesurments();
    }
  }, []);

  return null;
};
