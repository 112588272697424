import axios from "axios";

export interface T_farm {
  _id?: string;
  name: string;
  location: {
    latitude: number;
    longitude: number;
  };
  type?: [string];
  company: string;
  fiscalCode: string;
}

export const getFarms = (cb: (farms: T_farm[]) => void) => {
  const token = localStorage.getItem("token"); // Preia tokenul utilizatorului

  axios({
    method: "get",
    url: `${process.env.server}/api2/farm/getfarms`,
    timeout: 5000,
    headers: {
      Authorization: `JWT ${token}`,
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      cb(response.data);
    })
    .catch(async (e) => {
      console.error(await e.response, " as error for get Farms");

      cb(null);
    });
};
