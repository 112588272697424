// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Covered+By+Your+Grace&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100..700;1,100..700&family=Merienda:wght@300..900&family=Shantell+Sans:ital,wght@0,300..800;1,300..800&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* styles.css */
.text-black-b {
  color: black;
  font-weight: bold !important;
}

.typography-orange {
  color: #eec044;
  font-family: "Covered By Your Grace" !important;
}

.typography-grey {
  color: #878680;
}

.text-center-custom {
  text-align: center;
}

.button-green {
  background: #4baf47 !important;
  color: white !important;
  width: 180px;
  height: 50px;
  padding: 10px 20px;
  font-size: 18px;
  text-transform: none !important;
  border: none;
  cursor: pointer;
  border-radius: 5px !important;
}

.button-green:hover {
  background: green !important;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/style.css"],"names":[],"mappings":"AAAA,eAAA;AAIA;EACE,YAAA;EACA,4BAAA;AAAF;;AAGA;EACE,cAAA;EACA,+CAAA;AAAF;;AAGA;EACE,cAAA;AAAF;;AAGA;EACE,kBAAA;AAAF;;AAGA;EACE,8BAAA;EACA,uBAAA;EACA,YAAA;EACA,YAAA;EACA,kBAAA;EACA,eAAA;EACA,+BAAA;EACA,YAAA;EACA,eAAA;EACA,6BAAA;AAAF;;AAGA;EACE,4BAAA;AAAF","sourcesContent":["/* styles.css */\r\n@import url(\"https://fonts.googleapis.com/css2?family=Covered+By+Your+Grace&display=swap\");\r\n@import url(\"https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100..700;1,100..700&family=Merienda:wght@300..900&family=Shantell+Sans:ital,wght@0,300..800;1,300..800&display=swap\");\r\n\r\n.text-black-b {\r\n  color: black;\r\n  font-weight: bold !important;\r\n}\r\n\r\n.typography-orange {\r\n  color: #eec044;\r\n  font-family: \"Covered By Your Grace\" !important;\r\n}\r\n\r\n.typography-grey {\r\n  color: #878680;\r\n}\r\n\r\n.text-center-custom {\r\n  text-align: center;\r\n}\r\n\r\n.button-green {\r\n  background: #4baf47 !important;\r\n  color: white !important;\r\n  width: 180px;\r\n  height: 50px;\r\n  padding: 10px 20px;\r\n  font-size: 18px;\r\n  text-transform: none !important;\r\n  border: none;\r\n  cursor: pointer;\r\n  border-radius: 5px !important;\r\n}\r\n\r\n.button-green:hover {\r\n  background: green !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
