import { createSlice } from "@reduxjs/toolkit";
import { T_farmState, farmReducers } from "../actions/farm";

export const farmSlicer = createSlice({
  name: "farms",
  initialState: null as T_farmState,
  reducers: farmReducers,
});

export const {
  actions: { setFarms, setActiveFarm },
} = farmSlicer;
