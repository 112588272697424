import axios, { AxiosError } from "axios";
import { Moment } from "moment";
import { T_Point } from "../Pages/Map";

export interface T_machinery {
  _id?: string;
  name?: string;
  chassiSN: string;
  producer: string;
  model: string;
  year?: string;
  machineryType:
    | "tractor"
    | "combine"
    | "sprayer"
    | "auto"
    | "frontLoader"
    | "backhoe"
    | "cornCastrator"
    | "mower";
  serviceInterval: number;
  details?: string;
  deviceId: string;
  farmId: string;
  tehnicalDetails?: string;
  plateNo?: string;
  lastRevision?: Date;
  lastPositionData?: T_Position;
}

export interface T_Position {
  deviceid: string;
  time: string;
  latitude: string;
  longitude: string;
  altitude?: string;
  speed?: string;
  direction?: string;
  fuelrate?: string;
  enginespeed?: string;
}

export const getMachinerys = async (
  farmId: string,
  cb: (machineryes: T_machinery[]) => void
) => {
  const token = localStorage.getItem("token"); // Preia tokenul utilizatorului

  axios({
    method: "get",
    params: { farmId },
    url: `${process.env.server}/api2/machinery/getmachinerys`,
    headers: {
      Authorization: `JWT ${token}`,
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      cb(response.data ? response.data : []);
    })
    .catch(async (e) => {
      console.error(await e, " >>>> on getMachinerys fn ");
      cb([]);
    });
};

export const getLastPosition = (
  deviceId: string,
  cb: (position: T_Position | null) => void
) => {
  const token = localStorage.getItem("token"); // Preia tokenul utilizatorului

  axios({
    method: "get",
    url: `${process.env.server}/api2/machinery/lastposition`,
    params: { deviceId },
    headers: {
      Authorization: `JWT ${token}`,
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      if (response.status === 200) {
        cb(response.data);
      } else {
        cb(null);
      }
    })
    .catch((e: AxiosError) => {
      console.error(e, " >>>> on getPosition fn ");
      cb(null);
    });
};

export type T_geoJSON = [number, number, string?, number?];

export interface T_Path {
  path: T_geoJSON[];
  distance: number;
  consumption: number;
  timeGap: number;
  stationaryTime: number;
}

export const getMachineryPath = (
  data: {
    deviceId: string;
    start: Moment;
    end: Moment;
  },
  cb: (positions: T_Path | null) => void
) => {
  const token = localStorage.getItem("token"); // Preia tokenul utilizatorului

  axios({
    method: "get",
    url: `${process.env.server}/api2/machinery/path`,
    params: { deviceId: data.deviceId, start: data.start, end: data.end },
    headers: {
      Authorization: `JWT ${token}`,
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      if (response.status === 200) {
        cb(response.data);
      } else {
        cb(null);
      }
    })
    .catch(async (e) => {
      console.error(await e, " >>>> on getPath");

      cb(null);
    });
};

export type T_trackInfo = {
  consumption: number;
  workingMinutes: number;
  totalMinutes: number;
  area?: number;
  interval: { start: string; end: string };
};
export type T_LatLng = {
  latitude: number;
  longitude: number;
};

export const getConsumptionForPolygon = (
  data: {
    start: string;
    end: string;
    deviceId: string;
    polygon: T_LatLng[];
  },
  cb: (result: T_trackInfo) => void
) => {
  // return consumption inside that polygon
  const token = localStorage.getItem("token"); // Preia tokenul utilizatorului

  axios({
    method: "get",
    url: `${process.env.server}/api2/machinery/consumptionforpolygon`,
    params: data,
    headers: {
      Authorization: `JWT ${token}`,
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      if (response.status === 200) {
        cb(response.data);
      } else {
        cb(null);
      }
    })
    .catch(async (e: AxiosError) => {
      cb(null);
    });
};
