import {
  Alert,
  Box,
  Button,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import {
  faMapMarkerAlt,
  faPhone,
  faEnvelope,
  faStar,
  faAngleLeft,
  faAngleRight,
} from "@fortawesome/free-solid-svg-icons";
import {
  faInstagram,
  faTwitter,
  faFacebook,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

import React, { ReactElement, useState } from "react";
import { T_input } from "../Components/Inputs";
import { useTheme } from "@emotion/react";
import { sendForm } from "../Requests/contact";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  APIProvider,
  InfoWindow,
  Map,
  AdvancedMarker,
  Marker,
} from "@vis.gl/react-google-maps";

export interface T_contactForm {
  name: string;
  email: string;
  phone: string;
  message: string;
}

export interface T_alert {
  message: string;
  timeout?: number;
  severity: "success" | "info" | "warning" | "error";
}

export const Contact = (props: { id: string }): ReactElement | null => {
  const [form, setForm] = useState<T_contactForm | null>(null);
  const [alert, setAlert] = useState<T_alert | null>(null);

  const handleFormUpdate = (target: string, value: string) => {
    let newForm = { ...form };
    Object.assign(newForm, { [target]: value });
    setForm(newForm);
  };

  const handleAlertUpdate = (alert: T_alert) => {
    setAlert({
      severity: alert.severity,
      message: alert.message,
    });

    // eliminate alert
    setTimeout(() => setAlert(null), alert.timeout ? alert.timeout : 2000);
  };

  const handleSend = () => {
    // check if all the field ar presend
    let valid = true;
    let checkForm: any = { ...form };
    fields.forEach((field) => {
      let value = checkForm[field.value];
      if (!value && field.requred) valid = false;
    });

    if (valid) {
      sendForm(form, (done) => {
        setForm(null);
        if (done) {
          handleAlertUpdate({
            severity: "success",
            message: "Am transmis mesajul",
            timeout: 3000,
          });
        } else {
          handleAlertUpdate({
            severity: "error",
            message: "Nu am reușit trimiterea mesajului",
            timeout: 3000,
          });
        }
      });
    } else {
      handleAlertUpdate({
        severity: "error",
        message: "Completați măcar câmpurile obligatorii",
      });
    }
  };

  const fields: T_input[] = [
    {
      label: "Nume",
      value: "name",
      type: "text",
      requred: true,
    },
    {
      label: "Email",
      value: "email",
      type: "text",
    },
    {
      label: "Telefon",
      requred: true,
      value: "phone",
      type: "text",
    },
    { label: "Messaj", value: "message", type: "text", rows: 3 },
  ];

  return (
    <Grid id={props.id}>
      <Grid sx={{}} display={{ xs: "none", sm: "contents" }}>
        <Box className="bg-yellowBg py-5" textAlign="center">
          <Typography className="typography-orange" sx={{ fontSize: "24px" }}>
            Contact
          </Typography>
          <Typography
            className="text-black-b"
            fontSize={28}
            sx={{ fontFamily: "Manrope" }}
          >
            {"Ți-am atras atenția?"}
          </Typography>
        </Box>
        <Grid
          container
          justifyItems={"center"}
          alignItems={"center"}
          justifyContent={"center"}
          className="bg-yellowBg pb-5"
        >
          <Grid xs={11} md={7} item display={"flex"}>
            <Box
              className="p-3"
              sx={{
                borderTopLeftRadius: 20,
                borderBottomLeftRadius: 20,
                minHeight: 550,
                width: "50%",
                backgroundColor: "#24231D",
              }}
              alignContent={"center"}
            >
              {alert ? (
                <Stack spacing={2}>
                  <Alert variant="filled" severity={alert.severity}>
                    {alert.message}
                  </Alert>
                </Stack>
              ) : null}
              <Typography
                textAlign="center"
                className="text-white"
                fontSize={20}
                marginBottom={2}
                marginTop={2}
                sx={{ whiteSpace: "pre-line" }}
              >
                {"Completează formularul \nși te sunăm noi"}
              </Typography>
              <Grid container alignItems={"center"} justifyContent={"center"}>
                {fields.map((field) => {
                  return (
                    <Grid item width={"90%"} key={field.value}>
                      <TextField
                        variant="outlined"
                        className="bg-darkGrey text-white"
                        required={field.requred}
                        color="secondary"
                        multiline={Boolean(field.rows)}
                        onChange={(e) => {
                          handleFormUpdate(field.value, e.target.value);
                        }}
                        rows={field.rows}
                        fullWidth
                        label={field.label}
                        sx={{
                          borderRadius: 3,
                          marginTop: 2,
                        }}
                        InputProps={{
                          style: { color: "white" },
                        }}
                        InputLabelProps={{
                          style: { color: "white" },
                        }}
                        size={"small"}
                      />
                    </Grid>
                  );
                })}

                <Grid item alignContent={"center"} className=" mt-4">
                  <Typography
                    sx={{
                      textAlign: "center",
                      marginBottom: 2,
                      color: "white",
                    }}
                  >
                    Îndrăznește să explorezi universul Cultiva
                  </Typography>
                  <Box display={"flex"} justifyContent={"center"}>
                    <Button
                      variant="contained"
                      className="button-green"
                      onClick={handleSend}
                    >
                      Trimite
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box
              className="px-5"
              sx={{
                borderTopRightRadius: 20,
                borderBottomRightRadius: 20,
                minHeight: 500,
                width: "50%",
                backgroundColor: "white",
              }}
              alignContent={"center"}
            >
              <Typography sx={{ textAlign: "left", marginBottom: 2 }}>
                Sau ne găsești la
              </Typography>
              <Box sx={{ marginBottom: 3 }}>
                <APIProvider
                  apiKey="AIzaSyBxY7QOAGtxQZMuRwQJ9EULKpLT9qIFToU"
                  libraries={["core", "maps", "marker"]}
                >
                  <Map
                    style={{ width: "100%", height: "200px", borderRadius: 10 }}
                    mapId="mainMap"
                    defaultCenter={{ lat: 47.1755896, lng: 27.5798254 }}
                    defaultZoom={12}
                    mapTypeId="terrain"
                  >
                    <AdvancedMarker
                      position={{ lat: 47.1755896, lng: 27.5798254 }}
                    />
                  </Map>
                </APIProvider>
              </Box>
              <Grid
                container
                alignItems="center"
                alignContent={"center"}
                mt={3}
              >
                <Grid item>
                  <FontAwesomeIcon icon={faMapMarkerAlt} />
                </Grid>
                <Grid item ml={2}>
                  <Typography variant="body1">Iași, România</Typography>
                </Grid>
              </Grid>
              <Grid container alignItems="center">
                <Grid item>
                  <FontAwesomeIcon icon={faPhone} />
                </Grid>
                <Grid item ml={2}>
                  <Typography variant="body1">+40 723 350 033</Typography>
                </Grid>
              </Grid>
              <Grid container alignItems="center" sx={{ marginBottom: 3 }}>
                <Grid item>
                  <FontAwesomeIcon icon={faEnvelope} />
                </Grid>
                <Grid item ml={2}>
                  <Typography variant="body1">info@cultivaapp.ro</Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2} justifyContent={"flex-end"} mt={10}>
                <IconButton aria-label="Instagram" href="https://instagram.com">
                  <FontAwesomeIcon icon={faInstagram} />
                </IconButton>
                <IconButton aria-label="Twitter" href="https://twitter.com">
                  <FontAwesomeIcon icon={faTwitter} />
                </IconButton>
                <IconButton aria-label="Facebook" href="https://facebook.com">
                  <FontAwesomeIcon icon={faFacebook} />
                </IconButton>
                <IconButton aria-label="YouTube" href="https://youtube.com">
                  <FontAwesomeIcon icon={faYoutube} />
                </IconButton>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Grid>

      {/* mobile style */}
      <Grid id={props.id} display={{ xs: "block", sm: "none" }}>
        <Box className="bg-yellowBg py-5" textAlign="center">
          <Typography className="typography-orange" sx={{ fontSize: "24px" }}>
            Contact
          </Typography>
          <Typography
            className="text-black-b"
            fontSize={28}
            sx={{ fontFamily: "Manrope" }}
          >
            {"Ți-am atras atenția?"}
          </Typography>
        </Box>

        <Grid
          container
          justifyItems={"center"}
          alignItems={"center"}
          justifyContent={"center"}
          className="bg-yellowBg pb-5"
        >
          <Grid xs={10} item display={"flex"}>
            <Box
              className="p-3"
              sx={{
                borderRadius: 5,
                backgroundColor: "#24231D",
              }}
              alignContent={"center"}
              py={2}
            >
              {alert ? (
                <Stack spacing={2}>
                  <Alert variant="filled" severity={alert.severity}>
                    {alert.message}
                  </Alert>
                </Stack>
              ) : null}
              <Typography
                textAlign="center"
                className="text-white"
                fontSize={20}
                marginBottom={2}
              >
                {"Completează formularul \nși te sunăm noi"}
              </Typography>
              <Grid container alignItems={"center"} justifyContent={"center"}>
                {fields.map((field) => {
                  return (
                    <Grid item width={"90%"} key={field.value}>
                      <TextField
                        variant="outlined"
                        className="bg-darkGrey text-white"
                        required={field.requred}
                        color="secondary"
                        multiline={Boolean(field.rows)}
                        onChange={(e) => {
                          handleFormUpdate(field.value, e.target.value);
                        }}
                        rows={field.rows}
                        fullWidth
                        label={field.label}
                        sx={{
                          borderRadius: 3,
                          marginTop: 2,
                        }}
                        InputProps={{
                          style: { color: "white" },
                        }}
                        InputLabelProps={{
                          style: { color: "white" },
                        }}
                        size={"small"}
                      />
                    </Grid>
                  );
                })}

                <Grid item alignContent={"center"} className=" mt-4">
                  <Typography
                    sx={{
                      textAlign: "center",
                      marginBottom: 2,
                      color: "white",
                    }}
                  >
                    Îndrăznește să explorezi universul Cultiva
                  </Typography>
                  <Button
                    variant="contained"
                    onClick={handleSend}
                    sx={{
                      width: "100%",
                      borderRadius: 3,
                      fontFamily: "Manrope",
                    }}
                  >
                    Trimite
                  </Button>
                </Grid>
              </Grid>
              {/* <Grid container>
                <APIProvider
                  apiKey="AIzaSyCbmoTCniO_4-qigA7MBbEYI2D2o1OG400"
                  libraries={["core", "maps", "marker"]}
                >
                  <Map
                    style={{
                      width: "100%",
                      height: "180px",
                      borderRadius: 10,
                      display: "inline", 
                    }}
                    mapId="mainMap"
                    defaultCenter={{ lat: 47.1755896, lng: 27.5798254 }}
                    defaultZoom={12}
                    mapTypeId="terrain"
                  >
                    <Marker position={{ lat: 47.1755896, lng: 27.5798254 }} />
                  </Map>
                </APIProvider>
              </Grid> */}
              <Grid container spacing={2} justifyContent={"center"} mt={3}>
                <IconButton aria-label="Instagram" href="https://instagram.com">
                  <FontAwesomeIcon
                    icon={faInstagram}
                    style={{ color: "white" }}
                  />
                </IconButton>
                <IconButton aria-label="Twitter" href="https://twitter.com">
                  <FontAwesomeIcon
                    icon={faTwitter}
                    style={{ color: "white" }}
                  />
                </IconButton>
                <IconButton aria-label="Facebook" href="https://facebook.com">
                  <FontAwesomeIcon
                    icon={faFacebook}
                    style={{ color: "white" }}
                  />
                </IconButton>
                <IconButton aria-label="YouTube" href="https://youtube.com">
                  <FontAwesomeIcon
                    icon={faYoutube}
                    style={{ color: "white" }}
                  />
                </IconButton>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
