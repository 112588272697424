// export store

import { configureStore } from "@reduxjs/toolkit";
import { farmSlicer } from "./slicers/farm";
import { machinerySlicer } from "./slicers/machinary";

export const store = configureStore({
  reducer: {
    farms: farmSlicer.reducer,
    machinaries: machinerySlicer.reducer,
  },
});
