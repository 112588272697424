import { PayloadAction, current } from "@reduxjs/toolkit";
import { T_Position, T_machinery } from "../../Requests/machinery";

export const machineryReducers = {
  setMachineries: {
    reducer: (
      state: T_machinery[] | null,
      action: PayloadAction<T_machinery[]>
    ): T_machinery[] => {
      return action.payload ? [...action.payload] : null;
    },
    prepare: (machinaries: T_machinery[]) => ({
      payload: machinaries,
    }),
  },
  setMachinaryPosition: {
    reducer: (
      state: T_machinery[] | null,
      action: PayloadAction<T_Position>
    ): T_machinery[] => {
      const newState = current(state).map((machinary) => {
        let newMachinary = { ...machinary };
        if (machinary.deviceId === action.payload?.deviceid) {
          newMachinary.lastPositionData = action.payload;
        }
        return newMachinary;
      });

      return newState;
    },
    prepare: (position: T_Position) => ({
      payload: position,
    }),
  },
};
