import { ButtonBase, Grid, Typography, Box } from "@mui/material";
import React, { ReactElement, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faTimes,
  faUser,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import imgNav from "../assets/images/nav_img.png";

export interface T_navMenuItem {
  value: string; // as target value
  label: string; // value to display
  action: () => void;
}

const LoginAndSearchButtons = (): ReactElement => {
  const navigate = useNavigate();
  return (
    <Box display="flex" alignItems="center" justifyContent="center" gap={1}>
      <ButtonBase
        className="m-2"
        onClick={() => navigate("/login")}
        sx={{
          isplay: "flex",
          borderRadius: "50%",
          padding: "10px",
          flexDirection: "column",
        }}
      >
        <FontAwesomeIcon icon={faUser} size="sm" color="#2D2D2B" />
        <Typography
          variant="body2"
          sx={{
            color: "#2D2D2B",
            fontSize: "12px",
            marginTop: "4px",
            fontFamily: "Manrope",
          }}
        >
          Contul meu
        </Typography>
      </ButtonBase>
      <Box
        sx={{
          width: "1px",
          height: "20px",
          backgroundColor: "rgba(0, 0, 0, 0.2)",
          display: { xs: "none", md: "block" },
        }}
      />
      <ButtonBase
        className="m-2"
        sx={{
          borderRadius: "50%",
          padding: "20px",
        }}
      >
        <FontAwesomeIcon icon={faSearch} size="sm" color="black" />
      </ButtonBase>
    </Box>
  );
};

interface NavBarForDmProps {
  navMenu: T_navMenuItem[];
}

const NavBarForLG = ({ navMenu }: NavBarForDmProps): ReactElement => {
  return (
    <Grid
      container
      sx={{
        display: { xs: "none", lg: "flex" },
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0)), url(${imgNav})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        position: "relative",
        zIndex: 2,
      }}
      mb={-4}
    >
      <Grid
        item
        container
        justifyItems="center"
        alignItems="center"
        justifyContent="right"
        md={8}
        lg={7}
        mb={1}
      >
        {navMenu.map((element, index) => (
          <Grid item className="d-inline m-2 text-primary" key={element.value}>
            <ButtonBase className="p-2" onClick={element.action}>
              <Typography
                fontFamily="Manrope"
                className={index > 0 ? "text-grey" : "text-black-b"}
                fontSize={14}
              >
                {element.label}
              </Typography>
            </ButtonBase>
          </Grid>
        ))}
      </Grid>
      <Grid item justifyContent="center" md={3.65}>
        <LoginAndSearchButtons />
      </Grid>
    </Grid>
  );
};

export default NavBarForLG;

interface NavBarForXsProps {
  navMenu: T_navMenuItem[];
  menuOpen: boolean;
  setMenuOpen: (open: boolean) => void;
}

const NavBarForXs = ({
  navMenu,
  menuOpen,
  setMenuOpen,
}: NavBarForXsProps): ReactElement => {
  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      sx={{
        display: { md: "flex", lg: "none" },
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0)), url(${imgNav})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        position: "relative",
        zIndex: 2,
      }}
      mb={-4}
    >
      <Grid item sx={{ ml: 3, mb: 1 }}>
        <ButtonBase
          onClick={() => setMenuOpen(!menuOpen)}
          sx={{
            borderRadius: "50%",
            padding: "10px",
            "&:hover": {
              backgroundColor: "rgb(211, 211, 240)",
            },
          }}
        >
          <FontAwesomeIcon icon={faBars} size="lg" color="black" />
        </ButtonBase>
      </Grid>
      {menuOpen && (
        <Grid
          item
          container
          direction="column"
          alignItems="center"
          className="mobile-menu"
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            backgroundColor: "#fff",
            zIndex: 10,
          }}
        >
          <Grid item sx={{ alignSelf: "flex-end", padding: 2 }}>
            <ButtonBase
              onClick={() => setMenuOpen(false)}
              sx={{
                borderRadius: "50%",
                padding: "10px",
                "&:hover": {
                  backgroundColor: "rgb(211, 211, 240)",
                },
              }}
            >
              <FontAwesomeIcon icon={faTimes} size="lg" color="#b0b0b0" />
            </ButtonBase>
          </Grid>
          {navMenu.map((element) => (
            <Grid item key={element.value} className="m-2 text-primary">
              <ButtonBase className="p-2" onClick={element.action}>
                <Typography fontFamily="Manrope" className="text-grey">
                  {element.label}
                </Typography>
              </ButtonBase>
            </Grid>
          ))}
        </Grid>
      )}
      <Grid item md={4} mr={3}>
        <LoginAndSearchButtons />
      </Grid>
    </Grid>
  );
};

export const NavBar = (): ReactElement | null => {
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleNavigation = (id: string) => {
    if (location.pathname !== "/") {
      sessionStorage.setItem("scrollTo", id);
      navigate("/", { replace: true });
    } else {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
      setMenuOpen(false);
    }
  };

  const navMenu: T_navMenuItem[] = [
    { label: "Acasă", value: "root", action: () => handleNavigation("root") },
    {
      label: "Despre noi",
      value: "about",
      action: () => handleNavigation("about"),
    },
    {
      label: "Servicii",
      value: "services",
      action: () => handleNavigation("services"),
    },
    {
      label: "Inovatii tehnologice",
      value: "explore",
      action: () => handleNavigation("explore"),
    },
    {
      label: "Testimoniale",
      value: "testimonials",
      action: () => handleNavigation("testimonials"),
    },
    {
      label: "Contact",
      value: "contact",
      action: () => handleNavigation("contact"),
    },
  ];

  return (
    <>
      <NavBarForLG navMenu={navMenu} />
      <NavBarForXs
        navMenu={navMenu}
        menuOpen={menuOpen}
        setMenuOpen={setMenuOpen}
      />
    </>
  );
};
