import React, { ReactElement } from "react";
import {
  Grid,
  Typography,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  AppBar,
  Toolbar,
  responsiveFontSizes,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTractor,
  faSeedling,
  faPlateWheat,
  faCow,
  faPlayCircle,
  faCoins,
  faBalanceScaleLeft,
  faTruckPickup,
} from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import video from "../assets/video/video.mp4";
import offerCard1 from "../assets/images/offerCard1.png";
import offerCard2 from "../assets/images/offerCard2.png";
import offerCard3 from "../assets/images/offerCard3.png";
import offerCard4 from "../assets/images/offerCard4.png";
import { useState } from "react";

interface offerObject {
  image: string;
  icon: IconProp;
  title: string;
}

interface MoreInfoObject {
  image: string;
  title: string;
  description: string;
  index: number;
}

const VideoComponent = (): ReactElement => {
  const videoHedaer = [
    {
      title: "Solutii antreprenoriale",
      src: "#",
    },
    {
      title: "Inovatii tehnologice",
      src: "#",
    },
    {
      title: "Clienti Satisfacuti",
      src: "#",
    },
    {
      title: "Fermieri Experti",
      src: "#",
    },
  ];

  return (
    <>
      {/* <Grid
        container
        justifyContent="center"
        spacing={2}
        sx={{ display: { xs: "flex", md: "none" }, mt: 10, mb: 1 }}
      >
        {videoHedaer.map((element, index) => (
          <Grid item key={index}>
            <Button
              variant="contained"
              onClick={() => {
                const element = document.getElementById("expand");
                element.scrollIntoView({ behavior: "smooth" });
              }}
              sx={{
                padding: "20px 20px",
                background: "#4BAF47",
                textTransform: "none",
                "&:hover": {
                  background: "darkgreen",
                },
              }}
            >
              {element.title}
            </Button>
          </Grid>
        ))}
        <Grid item xs={12}>
          <Box
            sx={{
              background: "#F8F7F0",
              borderRadius: 10,
              height: "50px",
              mt: 2,
              p: 2,
            }}
          >
            <Typography className="text-black-b" align="center">
              Agricultura Sustenabilă – Viitorul nostru
            </Typography>
          </Box>
        </Grid>
      </Grid> */}
      <Box
        sx={{
          position: "relative",
          width: "100%",
          height: "100%",
          mt: { md: 12 },
        }}
      >
        <Box
          component="video"
          sx={{
            width: "100%",
            height: { md: "600px", xs: "auto", sm: "300px", lg: "600px" },
            objectFit: "cover",
            position: "relative",
          }}
          autoPlay
          muted
          loop
        >
          <source src={video} type="video/mp4" />
          Your browser does not support the video tag.
        </Box>
        <Box
          position="absolute"
          sx={{
            display: { md: "flex", xs: "flex" },
            justifyContent: "center",
            alignItems: "center",
            // backgroundColor: "#4caf50",
            top: "0%",
            // left: "calc(50% - 400px)",
            width: "100%",
          }}
        >
          <Box
            sx={{
              borderRadius: 2,
              display: "flex",
              background: "#4BAF47",
              mt: -5,
            }}
          >
            {videoHedaer.map((element, index) => (
              <Box
                key={index}
                sx={{
                  padding: {
                    xs: "8px 8px",
                    sm: "20px 20px",
                    md: "30px 30px",
                    lg: "50px 50px",
                  },
                  textTransform: "none",
                  color: "white",
                  fontFamily: "Manrope",
                  fontSize: { xs: 14, sm: 13, lg: 14 },
                  textAlign: "center",
                }}
              >
                {element.title}
              </Box>
            ))}
          </Box>
        </Box>
        <Box
          sx={{
            position: "absolute",
            top: "30%",
            left: "10%",
            zIndex: 2,
            color: "white",
            maxWidth: { xs: "200px", lg: "400px" },
            fontFamily: "Manrope",
          }}
        >
          <Typography
            sx={{
              display: { md: "flex", xs: "flex" },
              fontWeight: "bold",
              fontSize: { xs: "25px", md: "60px" },
              ml: {
                sm: 10,
                lg: 20,
              },
              fontFamily: "Manrope",
            }}
          >
            {`Agricultura Sustenabilă, Viitorul nostru!`}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

const OfferCard = ({ image, icon, title }: offerObject): ReactElement => {
  return (
    <Box
      sx={{
        position: "relative",
        width: "250px",
        height: "400px",
        overflow: "hidden",
        borderRadius: "16px",
      }}
    >
      <img
        src={image}
        alt="Descriere imagine"
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
        }}
      />
      <Box
        sx={{
          position: "absolute",
          top: "68%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "210px",
          height: "90px",
          backgroundColor: "white",
          borderRadius: "8px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          className="text-black-b text-center-custom "
          sx={{ fontSize: "16px" }}
        >
          {title}
        </Typography>
      </Box>
      <Box
        sx={{
          position: "absolute",
          top: "calc(60% - 20px)",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "70px",
          height: "70px",
          backgroundColor: "#C5CE38",
          borderRadius: "8px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "25px",
        }}
      >
        <FontAwesomeIcon icon={icon} size="lg" color="white" />
      </Box>
      <Box
        sx={{
          position: "absolute",
          bottom: "5%",
          left: "50%",
          transform: "translateX(-50%)",
        }}
      >
        <Button
          className="button-green"
          sx={{ width: "120px", height: "50px", fontSize: "10px" }}
        >
          Afla mai multe
        </Button>
      </Box>
    </Box>
  );
};

const Cards = (): ReactElement => {
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  const moreInfo: MoreInfoObject[] = [
    {
      image: offerCard1,
      title: "Trasabilitatea Activelor",
      description:
        "Cultivá îți permite să urmărești toate activele fermei în timp real, oferindu-ți o imagine clară și detaliată a ceea ce se întâmplă. Datele sunt automat înregistrate, garantând acuratețea și eliminând riscul erorilor de înregistrare manuală.",
      index: 0,
    },
    {
      image: offerCard2,
      title: "Estimarea Eficienței Activităților",
      description:
        "Cultivá automatizează înregistrarea activităților, calculul suprafețelor lucrate, orele de muncă și consumul de resurse. Cu aceste instrumente, poți gestiona ferma ca pe o afacere, având informații exacte și actualizate despre costuri și cheltuieli. Algoritmii de calcul automat afișează doar informațiile relevante pentru decizii inteligente, susținând creșterea profitabilității.",
      index: 1,
    },
    {
      image: offerCard3,
      title: "Amortizarea Costurilor",
      description:
        "Cultivá nu doar colectează date, ci le procesează pentru a oferi informații valoroase. Astfel, poți evalua costurile lucrărilor și amortizarea investițiilor. Interfața vizuală a aplicației permite procesarea rapidă a informațiilor și compatibilitatea cu alte programe pentru descărcare și partajare, economisind timp prețios.",
      index: 2,
    },
    {
      image: offerCard4,
      title: "Monitorizare autoturisme",
      description:
        "Cultivá înregistrează traseele autoturismelor din fermă, oferind informații în timp real despre locație, nivelul de carburant, condițiile de deplasare și revizii. Aceasta ajută la o mai bună organizare și management al resurselor de transport, toate monitorizate ușor prin aplicația mobilă.",
      index: 3,
    },
  ];

  const infoOffers: offerObject[] = [
    {
      image: offerCard1,
      icon: faTractor,
      title: "Trasabilitatea Activelor",
    },
    {
      image: offerCard2,
      icon: faBalanceScaleLeft,
      title: "Estimarea Eficienței Activităților",
    },
    {
      image: offerCard3,
      icon: faCoins,
      title: "Amortizarea Costurilor",
    },
    {
      image: offerCard4,
      icon: faTruckPickup,
      title: "Monitorizare autoturisme",
    },
  ];

  return (
    <>
      <Grid
        container
        alignContent={"center"}
        justifyContent={"center"}
        spacing={4}
        sx={{ mt: 5, mb: 20 }}
      >
        {infoOffers.map((element, index) => (
          <Grid item key={index}>
            <Box
              sx={{
                position: "relative",
                width: "240px",
                height: "400px",
                overflow: "hidden",
                borderRadius: "10px",
              }}
            >
              <img
                src={element.image}
                alt="Descriere imagine"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
              <Box
                sx={{
                  position: "absolute",
                  top: "72%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: "185px",
                  height: "90px",
                  backgroundColor: "white",
                  borderRadius: "8px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  className="text-black-b text-center-custom"
                  sx={{
                    fontSize: "16px",
                    mt: "12px",
                    width: "170px",
                  }}
                >
                  {element.title}
                </Typography>
              </Box>
              <Box
                sx={{
                  position: "absolute",
                  top: "calc(64% - 20px)",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: "60px",
                  height: "60px",
                  backgroundColor: "#EEC044",
                  borderRadius: "8px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "25px",
                }}
              >
                <FontAwesomeIcon icon={element.icon} size="2x" color="white" />
              </Box>
              <Box
                sx={{
                  position: "absolute",
                  bottom: "5%",
                  left: "50%",
                  transform: "translateX(-50%)",
                }}
              >
                <Button
                  className="button-green"
                  onClick={() =>
                    setSelectedIndex(index === selectedIndex ? null : index)
                  }
                  sx={{ width: "130px", height: "35px", fontSize: "12px" }}
                >
                  {selectedIndex === index ? "Vezi mai putin" : "Vezi mai mult"}
                </Button>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
      {selectedIndex !== null && (
        <Grid item alignContent={"left"} sx={{ ml: "2%" }} lg={2}>
          <Typography
            className="text-black-b"
            align="left"
            sx={{ mb: 4, fontSize: "30px" }}
          >
            {moreInfo[selectedIndex].title}
          </Typography>
          <Typography className="text-grey" align="left" sx={{ mb: 2 }}>
            {moreInfo[selectedIndex].description}
          </Typography>
        </Grid>
      )}
    </>
  );
};

export const OfferComponent = (props: { id: string }): ReactElement | null => {
  return (
    <>
      <Grid
        container
        id={props.id}
        justifyContent={"center"}
        sx={{
          mt: 10,
          width: "100%",
          textAlign: "center",
          mb: { xs: -30, lg: -35 },
        }}
      >
        <Box
          sx={{
            padding: { xs: "40px 100px", md: "40px 160px" },
            background: "#F8F7F0",
            width: "100%",
            height: 400,
          }}
        >
          <Typography
            className="typography-orange"
            sx={{ fontSize: "24px", marginTop: "20px" }}
          >
            Serviciile Cultivá
          </Typography>
          <Typography
            className="text-black-b"
            sx={{
              fontSize: { md: "28px", xs: "20px", fontFamily: "Manrope" },
              marginTop: "20px",
            }}
          >
            Monitorizeaza Profitabilitatea cu un Singur Click
          </Typography>
        </Box>
      </Grid>
      <Cards />
      <VideoComponent />
    </>
  );
};
