import axios, { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import { router } from "../Router";

export const registerAxiosInterceptors = () => {
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error: AxiosError) => {
      const status = error.response.status;
      if (status === 401) {
        // logout this user
        router.navigate("/logout");
      } else {
        return error;
      }
    }
  );
};
