import React, { ReactElement } from "react";
import { Grid, Typography, Box, Button, IconButton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronUp,
  faCircleCheck,
} from "@fortawesome/free-solid-svg-icons";
import image from "../assets/images/avantaje.png";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

interface benefitObject {
  color: string;
  title: string;
  description: string;
}

export const BenefiesComponent = (): ReactElement | null => {
  const [isExpanded, setIsExpanded] = useState(false);
  const navigate = useNavigate();

  const advantagesA2 = [
    "Administrare fermă la secundă",
    "Monitorizare automată a activităților fermei în timp real",
    "Managementul tuturor resurselor în timp real",
    "Estimare automată a suprafețelor lucrate și a costurilor",
    "Interogări cantitative și calitative în timp real",
    "Generare rapoarte de activitate, bonuri de lucru și consum",
  ];

  const handleButtonClick = () => {
    setIsExpanded(!isExpanded);
  };

  const benefits: benefitObject[] = [
    {
      color: "#4BAF47",
      title: "100% timp de inregistrare si calcul",
      description:
        "Cultivá gestionează în timp real activitățile fermei, înregistrarea personalului și lucrărilor agricole, bonurile de lucru și consum, generând rapoarte detaliate.",
    },
    {
      color: "#C5CE38",
      title: "100 % granular",
      description:
        "Se integrează cu orice echipament existent în fermă, oferind o evidență completă a gestiunii activelor.",
    },
    {
      color: "#EEC044",
      title: "Informații valoroase acționabile",
      description:
        "Datele sunt analizate și prelucrate automat, transformându-se în informații utile pentru luarea deciziilor informate.",
    },
  ];

  return (
    <Grid
      container
      justifyContent={"center"}
      alignContent={"center"}
      sx={{ marginTop: 15, marginBottom: 15 }}
      width={"100%"}
    >
      <Grid xs={12} sm={6} md={5} lg={3.7} mr={{ sm: 2 }}>
        <Box
          component="img"
          sx={{
            width: { md: "100%", xs: "100%" },
            height: { md: "auto", xs: "auto" },
          }}
          src={image}
          alt="img"
        />
      </Grid>
      <Grid
        alignContent={"center"}
        sm={5}
        xs={10}
        md={5}
        lg={3.7}
        mt={{ xs: 5, sm: 0 }}
      >
        <Typography
          className="typography-orange"
          fontSize={"24px"}
          sx={{ textAlign: { xs: "center", sm: "left" } }}
        >
          Avantajele solutiei noastre
        </Typography>
        <Typography
          className="text-black-b"
          sx={{
            fontSize: "28px",
            mb: 1,
            width: { md: "62%", xs: "100%" },
            lineHeight: 1.5,
            fontFamily: "Manrope",
            textAlign: { xs: "center", sm: "left" },
          }}
        >
          {`De ce să alegi soluțiile Cultivá`}
        </Typography>
        <Typography
          className="text-grey"
          sx={{
            mb: 2,
            // width: { md: "80%", xs: "100%" },
            lineHeight: 1.5,
            fontFamily: "Manrope",
            fontSize: 14,
            textAlign: "justify",
          }}
        >
          Cultivá nu este doar o inovație tehnologică, ci un consultant care
          automatizează sarcinile administrative migăloase, oferind o
          perspectivă clară și detaliată asupra fermei tale.
        </Typography>

        {benefits.map((element, index) => (
          <Grid item container key={index} spacing={1} sx={{ mb: 1 }}>
            <Grid item>
              <FontAwesomeIcon
                icon={faCircleCheck}
                color={element.color}
                style={{ fontSize: "30px", marginTop: 7, marginRight: 10 }}
              />
            </Grid>
            <Grid item xs>
              <Typography
                className="text-black-b"
                align="left"
                sx={{ fontSize: "18px", fontFamily: "Manrope" }}
              >
                {element.title}
              </Typography>
              <Typography
                className="text-grey"
                align="justify"
                sx={{
                  width: {
                    md: "100%",
                    xs: "100%",
                    fontFamily: "Manrope",
                    fontSize: 14,
                  },
                }}
              >
                {element.description}
              </Typography>
            </Grid>
          </Grid>
        ))}
        {/* <Button
          className="button-green"
          onClick={handleButtonClick}
          sx={{
            mt: 2,
          }}
        >
          {isExpanded ? "Vezi mai putin" : "Vezi mai mult"}
        </Button>

        {isExpanded && (
          <div style={{ marginTop: "16px" }}>
            <Typography
              color="#4BAF47"
              align="left"
              sx={{ mt: 2, fontWeight: "bold", fontSize: "26px", mb: 3 }}
            >
              Cultivá asistent automat de administare
            </Typography>
            <Typography
              className="text-grey"
              align="left"
              sx={{
                width: { md: "90%", xs: "100%" },
                fontSize: "16px",
                mb: 0.5,
              }}
            >
              Cu ani de studii în domeniul cercetării și dezvoltării în
              tehnologii avansate și cu experiență vastă în domeniul agricol,
              soluția Cultivá a fost creată de fermieri pentru fermieri, pentru
              a le veni în ajutor și a accelera dezvoltarea sustenabilă. Cultivá
              a luat naștere din dorința de a sprijini fermierii să devină
              lideri de piață în domeniul producției, ajutându-i în
              administrarea resurselor, astfel încât să aibă informații
              actualizate documentate în timp real, pentru a putea lua decizii
              informate pentru creșterea fermei.
            </Typography>
            <Typography
              className="text-grey"
              align="left"
              sx={{
                width: { md: "90%", xs: "100%" },
                fontSize: "16px",
                mb: 0.5,
              }}
            >
              Administrarea unei ferme este o afacere, iar Cultivá își propune
              să pună la dispoziția fermierilor uneltele necesare pentru o
              administrare cât mai ușoară. În acest sens, soluția Cultivá
              combină echipament hardware avansat și software creat la comandă
              pentru ei, pentru a ușura vizualizarea datelor de interes.
              Algoritmi de calcul automat analizează datele și furnizează
              informații concrete ce pot fi folosite în luarea deciziilor.
            </Typography>
            <Typography
              className="text-grey"
              align="left"
              sx={{
                width: { md: "90%", xs: "100%" },
                fontSize: "16px",
                mb: 3,
              }}
            >
              In acest sens, prin aplicatia mobila Cultiva se prezinta ca un
              asistent personal de incredere care administreaza datele fermei in
              timp real. Pe langa monitorizarea in timp real si generarea
              automata de raporturi de activitate csre pot fi exportate si
              importate direct in contabilitate, cultiva permite interogari de
              orice fel.
            </Typography>
            {advantagesA2.map((element, index) => (
              <Box
                key={index}
                sx={{ display: "flex", alignItems: "center", mb: 1 }}
              >
                <FontAwesomeIcon icon={faCircleCheck} color="#C5CE38" />
                <Typography
                  className="text-black-b"
                  align="left"
                  sx={{ fontSize: "16px", ml: 1 }}
                >
                  {element}
                </Typography>
              </Box>
            ))}
          </div>
        )} */}
        <Grid
          item
          display={"flex"}
          justifyContent={{ xs: "center", sm: "left" }}
        >
          <Button
            className="button-green mt-2"
            onClick={() => navigate("/feedback")}
          >
            Dă-ne un feedback
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
