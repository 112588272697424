/// admin page

import { Box, Grid, Alert } from "@mui/material";
import moment from "moment";
import React from "react";
import { ReactElement, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { T_button, Buttons } from "../Components/Buttons";
import { T_input, Inputs } from "../Components/Inputs";
import { T_MapData, BaseMap, T_MapMarker } from "../Components/Map";
import { T_tableData, TableView } from "../Components/View";
import { T_log, getDebugData } from "../Requests/debug";
import { T_machinery, getMachinerys } from "../Requests/machinery";
import { NavigationHeader } from "../Components/Navigation";
import { T_Modal } from "../Components/Modal";
import { v4 as uuid } from "uuid";

export const Admin = (): ReactElement | null => {
  const navigate = useNavigate();
  const [machinaries, setMachineries] = useState<T_machinery[]>();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [modalTarget, setModalTarget] = useState<T_Modal["target"]>();
  const [machineryData, setMachineryData] = useState<T_log[]>();
  const [tableRows, setTableRows] = useState<T_tableData["rows"]>();
  const [markers, setMarkers] = useState<T_MapData["markers"]>();
  const [alert, setAlert] = useState<{
    open: boolean;
    text?: string;
    severity?: "error" | "info" | "success" | "warning";
  }>({
    open: false,
  });
  const [form, setForm] = useState({
    machineryId: "",
    start: moment().subtract(20, "minutes"),
    end: moment().add(5, "minutes"),
  });

  useEffect(() => {
    // check if user is loged in or not
    let token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
    }

    // get all the machinery available
    getMachinerys(null, (machinaries) => {
      setMachineries(machinaries);
    });
  }, []);

  const inputs: T_input[] = [
    {
      type: "select",
      value: "machineryId",
      label: "Dispozitiv",
      options: machinaries?.map((machinery: T_machinery) => {
        return {
          label: machinery.name
            ? machinery.name
            : `${machinery.producer} ${machinery.model}`,
          value: machinery._id,
        };
      }),
    },
    {
      type: "dateTime",
      value: "start",
      label: "Start",
    },
    {
      type: "dateTime",
      value: "end",
      label: "End",
    },
  ];

  const handleChange = (target: T_input, value: any) => {
    let f = { ...form };
    Object.assign(f, { [target.value]: value });
    setForm(f);
  };

  const handleModalClose = () => {
    setAnchorEl(null);

    setModalTarget({
      type: null,
    });
  };

  const handleMarkerClick = (
    marker: T_MapMarker,
    target: HTMLButtonElement
  ) => {};

  const getLogs = () => {
    setMachineryData(null);
    setMarkers(null);
    setTableRows(null);
    // get data for this interval
    let machinery = machinaries.filter(
      (machinery) => machinery._id === form.machineryId
    )[0];

    if (machinery) {
      setAlert({
        open: true,
        text: "Preluăm date din loguri, o să dureze",
        severity: "info",
      });
      getDebugData(
        {
          deviceId: machinery.deviceId,
          start: form.start.toISOString(),
          end: form.end.toISOString(),
        },
        (debugData) => {
          setAlert({
            open: true,
            text: `Am primit datele, avem în total ${debugData?.length} date de afișat`,
            severity: "info",
          });
          setTimeout(() => {
            setAlert({ open: false });
          }, 1500);
          if (Array.isArray(debugData)) {
            setMachineryData(debugData);

            // create
            let tableRows: T_tableData["rows"] = [];

            let markers: T_MapData["markers"] = [];

            debugData?.forEach((data) => {
              if (data.converted?.length) {
                // we have converted data
                data.converted.forEach((message) => {
                  const timeGap = moment(data.logTime).diff(
                    moment(message.data.time),
                    "seconds"
                  );

                  tableRows.push([
                    moment(data.logTime).format("HH:mm:ss:SSS"),
                    moment(message.data.time).format("HH:mm:ss"),
                    timeGap > 0 ? timeGap : "",
                    data.type,
                    message.data.gsmSignal,
                    message.data.latitude,
                    message.data.longitude,
                    message.data.batteryVoltage,
                    message.data.fuelRate,
                    message.data.engineSpeed,
                    message.data.faultCodes,
                    message.data.alarm,
                    message.data.state,
                    data.rawMessage,
                    data.source,
                  ]);

                  // add this to markers
                  markers.push({
                    lat: message.data.latitude,
                    lng: message.data.longitude,
                    name: moment(message.data.time).format("HH:mm:ss"),
                    _id: uuid(),
                    collectTime:
                      moment(message.data.time).format("HH:mm:ss") +
                      Math.random(),
                  });
                });
              } else {
                tableRows.push([
                  moment(data.logTime).format("HH:mm:ss:SSS"),
                  "",
                  "",
                  data.type,
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  "",
                  data.reason ? data.reason : data.rawMessage,
                  data.source,
                ]);
              }
            });

            // save data to state
            setMarkers(markers);
            setTableRows(tableRows);
          } else {
            console.error("NOT valid data ", debugData);
          }
        }
      );
    } else {
      setAlert({
        open: true,
        text: "Nu avem selectat un device",
        severity: "error",
      });
      setTimeout(() => {
        setAlert({ open: false });
      }, 2000);
    }
  };

  const buttons: T_button[] = [
    {
      value: "preluareDate",
      label: "Vezi datele",
      action: () => getLogs(),
      variant: "contained",
    },
  ];

  const tableHeads: T_tableData["head"] = [
    {
      value: "LogTime",
      label: "Log time",
    },
    {
      value: "messageTime",
      label: "Message time",
    },
    {
      value: "gap",
      label: "Message gap",
    },
    {
      value: "directionType",
      label: "Direction",
    },
    {
      value: "gsmSignal",
      label: "Signal",
    },
    {
      value: "latitude",
      label: "Latitude",
    },
    {
      value: "longitude",
      label: "Longitude",
    },
    {
      value: "batteryVoltage",
      label: "Tensiune Baterie",
    },
    {
      value: "fuelRate",
      label: "Consum",
    },
    {
      value: "engineSpeed",
      label: "Rotații",
    },
    {
      value: "faultCodes",
      label: "Erori",
    },
    {
      value: "alarm",
      label: "Alarm",
    },
    {
      value: "state",
      label: "State",
    },

    { value: "rawMessage", label: "Mesaj" },
    {
      value: "source",
      label: "Source",
    },
  ];

  const handleRowClick = (row: any[]) => {};

  return (
    <>
      <NavigationHeader activePage="admin" />
      <Box>
        <Grid container>
          {alert.open ? (
            <Alert
              sx={{
                position: "absolute",
                top: 0,
                zIndex: 10000,
              }}
              variant="filled"
              severity={alert.severity}
            >
              {alert.text}
            </Alert>
          ) : null}
          <Grid
            container
            sx={{
              position: "sticky",
              top: 64,
              backgroundColor: "white",
            }}
          >
            <Grid xs={6} item container className="p-3">
              <Inputs
                inputs={inputs}
                values={form}
                key={"max"}
                onChange={handleChange}
              />
            </Grid>
            <Grid
              item
              xs={6}
              className="p-2"
              container
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Buttons buttons={buttons} />
            </Grid>
            <Grid
              xs={12}
              item
              className="pt-2 pb-2"
              sx={{
                height: 400,
              }}
            >
              <BaseMap
                markers={markers}
                actions={{
                  onClickMarker: handleMarkerClick,
                }}
              />
            </Grid>
          </Grid>

          <Grid item container xs={12}>
            <Grid xs={12} item>
              <TableView
                head={tableHeads}
                rows={tableRows}
                actions={{
                  rowClick: handleRowClick,
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
