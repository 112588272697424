import { faAnchor, faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  AppBar,
  IconButton,
  MenuItem,
  Toolbar,
  Typography,
  Menu,
} from "@mui/material";
import React, { ReactElement, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getUserInfo } from "../Requests/auth";

export interface T_MenuItem {
  value: "map" | "admin" | "policy" | "logout";
  label: string;
  scope?: string[];
}

export interface T_NavigationHeader {
  activePage: T_MenuItem["value"];
  title?: string;
}

export const NavigationHeader = (
  props: T_NavigationHeader
): ReactElement | null => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const navigate = useNavigate();

  const handleClick = (target: T_MenuItem) => {
    navigate(`/${target.value}`);
  };

  var menu: T_MenuItem[] = [
    {
      value: "map",
      label: "Hartă",
      scope: ["farmer"],
    },
    {
      value: "admin",
      label: "Administrare",
      scope: ["admin"],
    },
    {
      value: "policy",
      label: "Politica de confidențialitate",
      scope: ["farmer"],
    },
    {
      value: "logout",
      label: "Ieșire",
      scope: ["farmer", "admin"],
    },
  ];

  const user = getUserInfo();
  menu = menu.filter((menuItem) => {
    let valid = false;
    user.roles.forEach((role) => {
      if (menuItem.scope?.includes(role)) {
        valid = true;
      }
    });
    return valid;
  });

  return (
    <AppBar position="sticky">
      <Toolbar>
        <IconButton
          size="medium"
          edge="start"
          onClick={(event) => {
            setAnchorEl(event.currentTarget);
          }}
        >
          <FontAwesomeIcon icon={faBars} color="white" />
        </IconButton>
        <Typography fontSize={20} marginLeft={2}>
          {`${
            menu.filter((item) => item.value === props.activePage)[0].label
          } ${props.title ? " - " + props.title : ""}`}
        </Typography>
      </Toolbar>
      <Menu
        id="baseNav"
        anchorEl={anchorEl}
        open={open}
        onClose={() => {
          setAnchorEl(null);
        }}
      >
        {menu.map((item) => {
          return (
            <MenuItem key={item.value} onClick={() => handleClick(item)}>
              {item.label}
            </MenuItem>
          );
        })}
      </Menu>
    </AppBar>
  );
};
