import React, { ReactElement } from "react";
import { HeaderPage } from "../Components/Header";
import {
  Grid,
  Typography,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
} from "@mui/material";
import { Footer } from "../Components/Footer";
import { NavBar } from "../Components/NavBar";
import { Politica } from "../Components/PoliticaDeConf";

export const PoliticaDeConfidentialitate = (): ReactElement | null => {
  return (
    <div style={{ background: "#F8F7F0" }}>
      <HeaderPage />
      <NavBar />
      <Box>
        <Grid container className="d-flex  w-100 ">
          <Box
            className="d-flex justify-content-center align-items-center"
            sx={{ width: "100%", height: "150px", background: "#4BAF47" }}
          >
            <Grid item textAlign="center">
              <Typography
                sx={{
                  color: "#EEC044",
                  fontFamily: "Covered By Your Grace",
                  fontSize: "45px",
                  mt: 5,
                }}
              >
                Politica de confidențialitate
              </Typography>
            </Grid>
          </Box>
        </Grid>
        <Politica />
      </Box>
      <Footer />
    </div>
  );
};
