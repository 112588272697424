import axios from "axios";

export interface T_log {
  logTime: string;
  deviceType: string;
  type: string;
  source: string;
  rawMessage?: string;
  reason?: string;
  converted: {
    deviceId: string;
    messageSerialNo: string;
    data: {
      time: string;
      alarm: string;
      altitude: number;
      batteryVoltage: number;
      canTime: string;
      direction: number;
      engineRunTime: number;
      engineSpeed: number;
      engineTourq: number;
      frictionTourq: number;
      fuelConsumption: number;
      fuelLevel: number;
      fuelRate: number;
      gsmSignal: string;
      latitude: number;
      longitude: number;
      speed: number;
      state: string;
      throttleOpening: number;
      totalVehicleHour: 0;
      ureaTankLevel: 0;
      faultCodes: string;
    };
  }[];
}

export const getDebugData = async (
  props: {
    deviceId: string;
    start: string;
    end: string;
  },
  cb: (logs: T_log[]) => void
) => {
  const token = localStorage.getItem("token"); // Preia tokenul utilizatorului

  axios({
    method: "get",
    url: `${process.env.debugServe}/debug/logs`,
    params: props,
    headers: {
      Authorization: `JWT ${token}`,
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      cb(response.data);
    })
    .catch(async (e) => {
      console.error(await e, " >>>> on getDebugData fn ");
      cb([]);
    });
};
