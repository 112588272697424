import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { ReactElement } from "react";

export interface T_tableData {
  head: {
    value: string;
    label: string;
  }[];
  rows: any[][];
  actions?: {
    rowClick?: (row: any[]) => void;
  };
}

export const TableView = (props: T_tableData): ReactElement | null => {
  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            {props.head?.map((head) => {
              return <TableCell key={head.value}>{head.label}</TableCell>;
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.rows?.map((row, index) => {
            return (
              <TableRow
                key={`${String(row[0])}_${index}`}
                hover
                onClick={() => {
                  if (props.actions && props.actions.rowClick) {
                    props.actions.rowClick(row);
                  }
                }}
              >
                {row.map((cell, index) => {
                  return (
                    <TableCell key={`${String(cell)}_${index}`}>
                      {cell}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
