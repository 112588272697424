// handle contact send

import axios from "axios";
import { T_contactForm } from "../Pages/Contact";

export const sendForm = (form: T_contactForm, cb: (done: boolean) => void) => {
  axios({
    method: "post",
    params: { ...form },
    url: `${process.env.server}/api2/contact/form`,
    timeout: 5000,
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      cb(response.data);
    })
    .catch(async (e) => {
      console.error(await e.response, " as error for get Farms");

      cb(null);
    });
};
