import { createBrowserRouter } from "react-router-dom";
import { Base } from "./Pages/Base";
import React from "react";
import { Login } from "./Pages/Login";
import { Logout } from "./Pages/Logout";
import { Map } from "./Pages/Map";
import { Admin } from "./Pages/Admin";
import { Feedback } from "./Pages/Feedback";
import { PoliticaDeConfidentialitate } from "./Pages/Policy";

// export router
export const router = createBrowserRouter([
  {
    path: "/",
    element: <Base />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/admin",
    element: <Admin />,
  },
  {
    path: "/map",
    element: <Map />,
  },
  {
    path: "/logout",
    element: <Logout />,
  },
  {
    path: "*",
    element: <Base />,
  },
  {
    path: "/feedback",
    element: <Feedback />,
  },
  {
    path: "/policy",
    element: <PoliticaDeConfidentialitate />,
  },
]);
