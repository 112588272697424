import React, { ReactElement, useState } from "react";
import {
  Grid,
  Typography,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import combine from "../assets/images/harvester_2.png";
import { ReactComponent as farmCard1 } from "../assets/svg/monitoring.svg";
import { ReactComponent as farmCard2 } from "../assets/svg/costs.svg";
import { ReactComponent as farmCard3 } from "../assets/svg/reports.svg";
import imageForBigCircle from "../assets/images/combina.png";
import imageForSmallCircle from "../assets/images/picFarm.png";
import { ReactComponent as FarmerIcon } from "../assets/svg/smart-famer 1.svg";
import { ReactComponent as TechIcon } from "../assets/svg/report 1.svg";

interface IconObject {
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  title: string;
  description: string;
}

interface FarmCardProps {
  index: number;
  title: string;
  description: string;
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

const FarmCard = ({
  index,
  title,
  description,
  icon: Icon,
}: FarmCardProps): ReactElement => {
  return (
    <Card
      sx={{
        width: { md: 300, xs: "100%", sm: 170 },
        boxShadow: 3,
        borderRadius: 2,
        // padding: "10px 10px",
        height: { xs: 220, sm: 260, md: 220 },
        overflow: "visible",
        justifyContent: "space-between",
        alignContent: "center",
      }}
    >
      <CardContent
        sx={{ textAlign: "center", justifyContent: "space-between" }}
      >
        <Typography
          className="typography-orange"
          sx={{ fontSize: { xs: "20px", sm: "20px" } }}
        >
          Avantajul 0{index}
        </Typography>
        <Typography
          className="text-black-b"
          sx={{ fontFamily: "Manrope", fontSize: { xs: "18px", sm: "18px" } }}
        >
          {title}
        </Typography>
        <Typography
          className="text-black-b"
          sx={{ fontFamily: "Manrope", fontSize: "14px" }}
        >
          {description}
        </Typography>
      </CardContent>
      <Box display="flex" justifyContent="center">
        <Icon width={80} height={80} />
      </Box>
    </Card>
  );
};

const ShortAbout = (props: { id: string }): ReactElement => {
  const [isExpanded, setIsExpanded] = useState(false);
  const handleButtonClick = () => {
    setIsExpanded(!isExpanded);
  };

  const iconArray: IconObject[] = [
    {
      icon: FarmerIcon,
      title: "Control total al fermei, oriunde te afli",
      description: "ferma în buzunar /oriunde în lume",
    },
    {
      icon: TechIcon,
      title: "Decizii bazate pe date precise și automatizate",
      description: "de activitate automat",
    },
  ];

  const advantagesA = [
    "Administrare fermă la secundă",
    "Monitorizare automată a activităților fer...",
  ];

  const advantagesA2 = [
    "Administrare fermă la secundă",
    "Monitorizare automată a activităților fermei în timp real",
    "Managementul resurselor în timp real",
    "Estimare automată a suprafețelor lucrate și a costurilor",
    "Interogări cantitative și calitative în timp real",
    "Generare rapoarte de activitate, bonuri de lucru și consum",
  ];

  return (
    <Grid
      container
      id={props.id}
      justifyContent="center"
      sx={{
        mt: { sm: "35%", md: "-15%", lg: "-10%" },
        mb: { md: 10, xs: 10 },
        pt: { xs: 5, md: 10 },
        pb: { xs: 5, md: 10 },
        px: { xs: 3, md: 0 },
      }}
      display={"flex"}
    >
      <Grid item md={5} sm={10} xs={12} lg={4.7}>
        <Box
          sx={{
            position: "relative",
            // mt: { xs: 3 },
            mb: 10,
            textAlign: "center",
          }}
        >
          <Box
            component="img"
            sx={{
              width: { md: 400, xs: "100%", sm: 300, lg: 450 },
              height: { md: 400, xs: "auto", sm: "auto", lg: "auto" },
              borderRadius: "50%",
              overflow: "hidden",
              objectFit: "cover",
              mx: "auto",
            }}
            src={imageForBigCircle}
            alt="img"
          />
          <Box
            component="img"
            sx={{
              width: { md: 220, xs: 140, lg: 200 },
              height: { md: 220, xs: 140, lg: 200 },
              borderRadius: "50%",
              overflow: "hidden",
              border: { md: "10px solid #fff", xs: "6px solid #fff" },
              objectFit: "cover",
              position: "absolute",
              top: "70%",
              left: { md: "-10%", xs: "50%", lg: "5%" },
              transform: { xs: "translateX(-50%)", md: "translateX(0)" },
            }}
            src={imageForSmallCircle}
            alt="img"
          />
        </Box>
      </Grid>

      <Grid
        item
        md={5}
        xs={12}
        sm={10}
        lg={4}
        sx={{ textAlign: { md: "left", xs: "center" } }}
      >
        <Typography className="typography-orange" sx={{ fontSize: "24px" }}>
          Despre noi
        </Typography>
        <Typography
          className="text-black-b"
          sx={{
            fontSize: { md: "38px", xs: "30px" },
            maxWidth: { md: "95%" },
            fontFamily: "Manrope",
            mt: "20px",
            mb: "20px",
          }}
        >
          Soluții inteligente de administrare a fermei
        </Typography>
        <Typography
          className="text-grey"
          sx={{
            fontSize: "16px",
            mb: 3,
            fontFamily: "Manrope",
            textAlign: "justify",
          }}
        >
          Cultivá este creată de fermieri pentru fermieri, cu scopul de a
          îmbunătăți productivitatea și de a dezvolta aptitudinile
          antreprenoriale. Aplicația noastră oferă unelte automate pentru
          înregistrarea și monitorizarea activităților agricole, fără a schimba
          modul în care lucrezi. Beneficiezi de control total și evidența
          costurilor în timp real.
        </Typography>

        <Grid container spacing={2}>
          {iconArray.map((element, index) => {
            const { icon: Icon, title } = element;
            return (
              <Grid
                item
                sm={6}
                spacing={1}
                mx={"auto"}
                md={6}
                key={index}
                display="flex"
                alignItems="center"
                justifyContent={{ xs: "flex-start", md: "flex-start" }}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  mr={2}
                >
                  <Icon width={70} height={70} style={{ fill: "orange" }} />
                </Box>
                <Typography
                  className="text-black-b"
                  sx={{
                    fontFamily: "Manrope",
                    fontSize: { sx: "18px", md: "16px", lg: "18px" },
                    fontWeight: "bold",
                    textAlign: { xs: "left", md: "justify", lg: "justify" },
                  }}
                >
                  {title}
                </Typography>
              </Grid>
            );
          })}
        </Grid>

        {isExpanded ? (
          <Box sx={{ mt: 2 }}>
            {advantagesA2.map((element, index) => (
              <Box
                key={index}
                sx={{ display: "flex", alignItems: "center", mb: 1, ml: 1 }}
              >
                <FontAwesomeIcon icon={faCircleCheck} color="#C5CE38" />
                <Typography
                  className="text-black-b"
                  sx={{ fontSize: "16px", ml: 1, fontFamily: "Manrope" }}
                >
                  {element}
                </Typography>
              </Box>
            ))}
            <Typography
              color="#4BAF47"
              sx={{
                fontWeight: "bold",
                fontSize: "18px",
                mb: 2,
                mt: 2,
                fontFamily: "Manrope",
              }}
            >
              Cultivá - Asistentul tău automat de administrare
            </Typography>
            <Typography
              className="text-grey"
              sx={{
                fontSize: "16px",
                mb: 2,
                fontFamily: "Manrope",
                textAlign: "justify",
              }}
            >
              Cu o bază solidă în cercetare și dezvoltare de tehnologii
              avansate, precum și o vastă experiență în agricultură, Cultivá a
              fost creat pentru a sprijini fermierii să devină lideri de piață
              în producție. Aplicația ușurează administrarea resurselor și oferă
              informații actualizate în timp real pentru decizii informate.
            </Typography>
          </Box>
        ) : (
          <Box sx={{ mt: 2, ml: 1 }}>
            {advantagesA.map((element, index) => (
              <Box
                key={index}
                sx={{ display: "flex", alignItems: "center", mb: 1 }}
              >
                <FontAwesomeIcon icon={faCircleCheck} color="#C5CE38" />
                <Typography
                  className="text-black-b"
                  sx={{ fontSize: "16px", ml: 1, fontFamily: "Manrope" }}
                >
                  {element}
                </Typography>
              </Box>
            ))}
          </Box>
        )}
        <Box sx={{ mt: 4, textAlign: { lg: "left", sx: "center" } }}>
          <Button
            variant="contained"
            onClick={handleButtonClick}
            className="button-green"
            sx={{ fontFamily: "Manrope" }}
          >
            {isExpanded ? "Vezi mai puțin" : "Vezi mai mult"}
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export const HeroSection = (): ReactElement | null => {
  const cardInfo = [
    {
      index: 1,
      title: "Monitorizare în timp real ",
      description: "a activităților agricole",
      icon: farmCard1,
    },
    {
      index: 2,
      title: "Costuri exacte ",
      description: "pe hectar și utilaj",
      icon: farmCard2,
    },
    {
      index: 3,
      title: "Rapoarte de activitate",
      description: "zilnice, lunare, anuale",
      icon: farmCard3,
    },
  ];

  return (
    <Box>
      <Box sx={{ position: "relative", width: "100%", zIndex: 1 }}>
        <Box
          sx={{
            position: "relative",
            width: "100%",
            height: { md: "700px", xs: "500px" },
            overflow: "hidden",
            mb: { md: 40, xs: 10 },
          }}
        >
          <Box
            component="img"
            src={combine}
            alt="Combine Harvester"
            sx={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              filter: "brightness(60%)",
            }}
          />
          <Grid
            container
            direction="column"
            sx={{
              position: "absolute",
              top: { md: "20%", xs: "25%" },
              color: "white",
              textAlign: "left",
            }}
          >
            <Grid item md={6} sx={{ ml: { lg: "18%", md: "20%", xs: "5%" } }}>
              <Typography
                sx={{
                  textTransform: "uppercase",
                  fontFamily: "Josefin Sans",
                  fontSize: { xs: "20px", md: "24px" },
                }}
              >
                Bun venit în Universul Cultivá!
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Covered By Your Grace",
                  fontSize: { xs: "50px", sm: "60px", md: "70px", lg: "100px" },
                  maxWidth: { lg: "53%", md: "50%", xs: "100%", sm: "60%" },
                  wordWrap: "break-word",
                  lineHeight: "1",
                  fontWeight: "500",
                  mt: "20px",
                }}
              >
                Cultura antreprenoriala
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Covered By Your Grace",
                  fontSize: { xs: "35px", md: "50px", lg: "60px" },
                }}
              >
                pentru fermieri dedicați
              </Typography>
              <Button
                onClick={() => {
                  const element = document.getElementById("about");
                  if (element) {
                    element.scrollIntoView({ behavior: "smooth" });
                  }
                }}
                className="button-green"
                sx={{
                  mt: 5,
                }}
              >
                Mai multe ...
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Grid
          container
          justifyContent="center"
          spacing={{ sm: 3, md: 3, lg: 4.5 }}
          sx={{
            display: { xs: "none", sm: "flex", md: "flex" },
            position: "absolute",
            top: { sm: "95%", md: "90%" },
            zIndex: 2,
          }}
        >
          {cardInfo.map((element) => (
            <Grid item key={element.index}>
              <FarmCard
                index={element.index}
                title={element.title}
                description={element.description}
                icon={element.icon}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
      <Grid
        container
        justifyContent="center"
        spacing={4}
        sx={{
          display: { xs: "flex", sm: "none", md: "none" },
          px: 3,
        }}
      >
        {cardInfo.map((element) => (
          <Grid item xs={12} key={element.index}>
            <FarmCard
              index={element.index}
              title={element.title}
              description={element.description}
              icon={element.icon}
            />
          </Grid>
        ))}
      </Grid>

      <ShortAbout id="about" />
    </Box>
  );
};
