import React, { ReactElement } from "react";
import { Box, Typography } from "@mui/material";

export const Politica = (): ReactElement => {
  return (
    <Box
      sx={{
        padding: "20px",
        maxWidth: "900px",
        margin: "0 auto",
        backgroundColor: "#f9f9f9",
        borderRadius: "8px",
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
        marginTop: "100px",
        textAlign: "justify",
        px: 5,
        py: 5,
      }}
    >
      {/* <Typography variant="h4" component="h1" gutterBottom>
        Politica de Confidențialitate
      </Typography> */}

      <Typography variant="body1" paragraph>
        Stimate Utilizator, vă invităm să găsiți mai jos Politica privind
        prelucrarea datelor cu caracter personal pe site-ul{" "}
        <a href="http://cultivaapp.ro">http://cultivaapp.ro</a> cât și în
        aplicațiile web și mobile Cultivá disponibile pe site și în Apple Store
        și Google Play.
      </Typography>

      <Typography variant="body1" paragraph>
        <strong>Operatorul de date:</strong> S.C. Green Rent S.R.L., cu sediul
        în Iași, înregistrată la Oficiul Registrului Comerțului de pe lângă
        Tribunalul Iași sub nr. J22/2947/2019, având un cod unic de înregistrare
        RO41639487, denumit în continuare Furnizor.
      </Typography>

      <Typography variant="body1" paragraph>
        Această Politică de confidențialitate stabilește Termenii și Condițiile
        în care S.C. Green Rent SRL prelucrează datele cu caracter personal,
        măsurile de confidențialitate implementate și modul în care utilizăm
        datele cu caracter personal pe care ni le încredințați accesând Site-ul
        Web, Aplicația Web, precum și Aplicația mobilă. Această Politică de
        confidențialitate poate fi actualizată periodic iar cea mai recentă
        versiune va fi găsită în Aplicație și pe Site-ul nostru.
      </Typography>

      <Typography variant="body1" paragraph>
        S.C. Green Rent SRL se angajează să protejeze confidențialitatea
        vizitatorilor și utilizatorilor. Politica de confidențialitate descrie
        ce date prelucrează S.C. Green Rent SRL și modul în care utilizăm datele
        cu caracter personal pentru a ajuta utilizatorii și vizitatorii noștri
        să utilizeze contul din Aplicația mobilă, Aplicația Web (denumite în
        continuare împreună "Aplicația") sau site-ul web precum și pentru
        serviciile noastre la cerere (denumite colectiv "servicii") pentru a
        oferi beneficii îmbunătățite. Vă rugăm să citiți cu atenție următoarele
        prevederi, astfel încât să fiți informat despre prevederile din Politica
        noastră de confidențialitate.
      </Typography>

      <Typography variant="body1" paragraph>
        Vă rugăm să aveți în vedere că pentru plata online furnizorul de
        servicii plati utilizează serviciile Green Rent SRL în calitate de
        Persoană Împuternicită în prelucrarea datelor și astfel, furnizorul de
        plati prelucrează datele dumneavoastră cu caracter personal pentru noi,
        așa cum sunt enumerate în această Politică de confidențialitate.
      </Typography>

      <Typography variant="body1" paragraph>
        S.C. Green Rent SRL își rezervă dreptul de a modifica această Politică
        de confidențialitate, dacă va fi necesar. Dacă aveți întrebări despre
        acest document, vă rugăm să ne contactați la{" "}
        <a href="mailto:client@cultivapp.ro">client@cultivapp.ro</a>.
      </Typography>

      <Typography variant="h6" component="h2" gutterBottom>
        1. Definiții
      </Typography>

      <Typography variant="body1" paragraph>
        <strong>"Date cu caracter personal"</strong> înseamnă orice informații
        despre o persoană fizică identificată sau identificabilă ("Persoana
        vizată") care poate fi identificată, direct sau indirect, în special
        prin referire la un identificator, cum ar fi un nume, un număr de
        identificare, o localizare a datelor, un identificator online sau cu
        privire la unul sau mai multe elemente specifice identității sale
        fizice, fiziologice, genetice, ideologice, economice, culturale sau
        sociale a persoanei fizice; în conformitate cu articolul 4 punctul 1 din
        Reg. UE 679/2016.
      </Typography>

      <Typography variant="body1" paragraph>
        <strong>"Prelucrarea datelor cu caracter personal"</strong> înseamnă
        orice operațiune sau set de operațiuni efectuate asupra datelor cu
        caracter personal sau asupra seturilor de date cu caracter personal, cu
        sau fără utilizarea unor mijloace automatizate, cum ar fi colectarea,
        înregistrarea, organizarea, structurarea, stocarea, adaptarea sau
        modificarea, extragerea, consultarea, utilizarea, divulgarea prin
        transmitere, diseminarea sau punerea la dispoziție în orice alt mod,
        alinierea sau combinarea, restricționarea, ștergerea sau distrugerea
        datelor conform art. 4 pct. 2 din Reg. UE 679/2016.
      </Typography>

      <Typography variant="body1" paragraph>
        <strong>"GDPR"</strong> Regulamentul (UE) 2016/679 al Parlamentului
        European și al Consiliului din 27 aprilie 2016 privind protecția
        persoanelor fizice în ceea ce privește prelucrarea datelor cu caracter
        personal și privind libera circulație a acestor date și de abrogare a
        Directivei 95/46/CE (Regulamentul general privind protecția datelor).
      </Typography>

      <Typography variant="body1" paragraph>
        <strong>"Utilizator/persoană vizată"</strong> se referă la o persoană
        care utilizează aplicațiile și/sau s-a înregistrat în cadrul
        aplicațiilor Cultivá pentru utilizarea sau utilizarea potențială a
        serviciului, persoana fizică ale cărei date sunt prelucrate.
      </Typography>

      <Typography variant="body1" paragraph>
        <strong>"Persoană împuternicită de operator"</strong> înseamnă persoana
        fizică sau juridică, autoritatea publică, agenția sau alt organism care
        prelucrează datele cu caracter personal în numele operatorului.
      </Typography>

      <Typography variant="h6" component="h2" gutterBottom>
        2. Dispoziții generale
      </Typography>

      <Typography variant="body1" paragraph>
        S.C. Green Rent SRL nu colectează niciodată cu bună știință informații
        personale de la copii cu vârsta sub 14 ani. Aplicația este destinată
        utilizării numai de către adulți cu vârsta de 16 ani sau mai mult. În
        cazul în care considerați că copilul dumneavoastră cu vârsta sub 18 ani
        a utilizat Aplicația și, prin urmare, ne-a furnizat date cu caracter
        personal cu care nu sunteți de acord, vă rugăm să ne contactați la{" "}
        <a href="mailto:client@cultivapp.ro">client@cultivapp.ro</a>. Ne vom
        strădui apoi să ștergem contul din Aplicații și datele cu caracter
        personal corespunzătoare, conform Termenilor și Condițiilor de
        utilizare.
      </Typography>

      <Typography variant="h6" component="h2" gutterBottom>
        3. Sursa datelor cu caracter personal
      </Typography>

      <Typography variant="body1" paragraph>
        Colectăm și prelucrăm date cu caracter personal în diferite moduri.
        Datele cu caracter personal sunt furnizate în mod voluntar de către
        utilizator în timpul creării și/sau modificării profilului de
        utilizator, atunci când interacționează cu sau utilizează Aplicația
        și/sau serviciile și prin comunicarea prin e-mail. Pentru utilizatorii
        Aplicației și/sau serviciilor, datele prelucrate includ în special
        următoarele informații: nume, adresă, adresă de e-mail, parolă
        (criptată), utilizator la conectare, adresă IP, informații despre cardul
        de debit/credit (care nu vor fi salvate de S.C. Green Rent SRL), adresă
        de e-mail profesională, adresă de domiciliu, alți rezidenți, ocazie,
        semnătură, locație, traseu deplasare.
      </Typography>

      <Typography variant="body1" paragraph>
        Informațiile asupra plății sunt procesate de un furnizor extern.
        Procesul de plată este autorizat de procesatorul de plăți și confirmat
        în sistemul nostru.
      </Typography>

      <Typography variant="body1" paragraph>
        Datele dumneavoastră cu caracter personal pot fi obținute din surse
        externe și publice, în ipoteza în care Aplicațiile verifică IP-ul
        dispozitivului utilizat, determină locația dumneavoastră sau utilizează
        cookie-uri esențiale, operaționale, analitice sau de marketing. Sunt
        utilizate date cu caracter personal cum ar fi locația și traseul de
        deplasare în timpul programului de muncă, pentru a furniza angajatorului
        în interiorul Aplicației, datele necesare cu privire la aceste aspecte.
      </Typography>

      <Typography variant="h6" component="h2" gutterBottom>
        4. Descrierea procesului
      </Typography>

      <Typography variant="body1" paragraph>
        Pentru utilizarea aplicației Clientul își va crea un cont de utilizator,
        urmat de crearea uneia sau mai multor conturi pentru fermele pe care le
        are în administrație și pentru care dorește sa utilizeze serviciile
        aplicației Cultivá. La crearea unui cont în aplicație, utilizatorul va
        furniza date cu caracter personal, precum următoarele: nume, data
        nașterii, școala absolvită, număr de telefon, adresă de email, date
        despre ferma pe care o are în administrare, numele firmei sub care
        administrează ferma, locația fermei, utilajele și automobilele avute în
        administrare, tipul și modelul acestora, numerele de înmatriculare,
        numele și modelul implementurilor agricole folosite pentru efectuarea
        activităților agricole precum și date cu caracter personal precum nume,
        data nașterii, școala absolvită, număr de telefon, adresă de email,
        certificate, acreditări ale personalului angajat să execute lucrările
        agricole.
      </Typography>

      <Typography variant="h6" component="h2" gutterBottom>
        5. Datele cu caracter personal pe care le colectăm
      </Typography>

      <Typography variant="body1" paragraph>
        S.C. Green Rent SRL este administratorul Aplicației și prelucrează
        datele dumneavoastră cu caracter personal, cum ar fi numele, numărul de
        telefon, adresa de domiciliu și/sau altă adresă, adresa de e-mail,
        locația, traseul de deplasare într-o anumită perioadă de timp, și poate
        utiliza datele de contact în scopuri de marketing, cu consimțământul
        dvs. Angajații S.C. Green Rent SRL operează aplicația Cultivá și site-ul
        platforma <a href="http://cultivaapp.ro">http://cultivaapp.ro</a> și pot
        vizualiza detaliile legate de identificarea persoanei, locație, IP,
        traseu de deplasare într-un anumit interval de timp, cantitatea de
        combustibil. S.C. Green Rent SRL oferă butoane de contact și de
        notificări în cadrul platformei aplicației funcții care permit
        angajaților S.C. Green Rent SRL să comunice cu dumneavoastră într-o
        formă personalizată și să vă furnizeze informații relevante la momentul
        potrivit.
      </Typography>

      <Typography variant="body1" paragraph>
        În cazurile în care ne furnizați date aparținând altor persoane conform
        legii, este responsabilitatea dumneavoastră să vă asigurați că datele cu
        caracter personal în cauză și prelucrarea ulterioară de către noi sunt
        în conformitate cu acordul acestora și că utilizarea datelor altor
        persoane vizate respectă legile aplicabile privind protecția datelor. De
        exemplu, trebuie să informați persoana vizată și, dacă este necesar, să
        obțineți consimțământul acesteia pentru prelucrarea datelor sale cu
        caracter personal în scopurile descrise în această Politică de
        confidențialitate.
      </Typography>

      <Typography variant="body1" paragraph>
        Datele dumneavoastră cu caracter personal ce țin de efectuarea plății
        vor fi prelucrate de S.C. Green Rent SRL și salvate de furnizorul extern
        de servicii de plată.
      </Typography>

      <Typography variant="h6" component="h2" gutterBottom>
        6. În ce scopuri prelucrăm datele dumneavoastră cu caracter personal?
      </Typography>

      <Typography variant="h6" component="h3" gutterBottom>
        6.1 Funcționarea tehnică și funcționalitatea site-ului web și a
        aplicației
      </Typography>

      <Typography variant="body1" paragraph>
        Atunci când vizitați site-ul nostru, pentru a vedea oferta noastră sau
        pentru a utiliza serviciile noastre, administratorii noștri web pot
        prelucra datele dumneavoastră cu caracter personal, respectiv de
        identificare, contact și datele tehnice, cum ar fi adresa IP, site-urile
        web pe care le vizitați, browser-ul de internet pe care îl utilizați,
        cel pe care l-ați utilizat în prealabil și ulterior site-urile vizitate
        și durata vizitei/sesiunii, astfel încât să putem garanta buna
        funcționare a site-ului nostru web. În anumite cazuri, browser-ul vă
        poate solicita, de asemenea, să partajați locația curentă pentru a
        optimiza experiența utilizatorului. Aceste date tehnice permit
        administratorilor noștri să gestioneze site-ul, de exemplu prin
        rezolvarea problemelor tehnice sau îmbunătățirea accesului la anumite
        zone ale site-ului web. Acesta este modalitatea în care ne asigurăm că
        puteți găsi informațiile relevante furnizate pe site-ul web rapid și
        ușor.
      </Typography>

      <Typography variant="body1" paragraph>
        Dacă utilizați Aplicația noastră mobilă sau Site-ul web, prelucrăm, de
        asemenea, datele dumneavoastră cu caracter personal ce se referă la date
        tehnice, cum ar fi, de exemplu, adresa IP și tipul de dispozitiv,
        locația, traseul parcurs într-o anumită perioadă de timp. Utilizăm
        aceste date pentru a furniza serviciile, pentru a garanta funcțiile
        Aplicației, pentru a rezolva probleme tehnice, pentru a vă oferi
        versiunea corectă și actualizată a Aplicației și pentru a îmbunătăți în
        continuare funcționalitatea acesteia.
      </Typography>

      <Typography variant="body1" paragraph>
        Notificările push trebuie să fie activate atunci când utilizați
        Aplicația noastră mobilă. Acestea sunt necesare și esențiale pentru ca
        S.C. Green Rent SRL să vă poată comunica informații despre actualizările
        Aplicației, ofertele disponibile sau actualizate precum și punctele
        acumulate sau ofertele răscumpărate.
      </Typography>

      <Typography variant="body1" paragraph>
        Temeiul juridic pentru operarea și funcționalitatea tehnică a Site-ului
        web și a Aplicației este art. 6 Alin. 1 lit. f din Regulamentul 679/2016
        al Parlamentului European (pe baza interesului nostru legitim de a vă
        oferi toate funcționalitățile site-ului web și ale aplicației) și art. 6
        Alin. 1 lit. b din Regulamentul 679/2016 al Parlamentului European
        (inițierea contractului și executarea contractului).
      </Typography>

      <Typography variant="h6" component="h3" gutterBottom>
        6.2 Serviciul Clienți
      </Typography>

      <Typography variant="body1" paragraph>
        Când vă înregistrați ca utilizator Cultivá, vă colectăm numele, numărul
        de telefon, adresa de e-mail, parola, adresa IP și tipul dispozitivului
        dvs., precum și numărul cardului de debit/credit și data expirării
        cardului, locația, traseul parcurs într-un anumit interval de timp (așa
        cum e descris mai sus).
      </Typography>

      <Typography variant="body1" paragraph>
        Utilizăm datele dumneavoastră cu caracter personal și informațiile de
        contact pentru a furniza serviciile, în scopuri de comunicare legate de
        serviciile furnizate (comunicare date referitoare la angajați,
        comunicare date referitoare la identificarea terenurilor înregistrate la
        Agenția de Plăți și Intervenție pentru Agricultură, s.a. m.d.) și pentru
        anunțuri legate de servicii, cum ar fi atunci când serviciile noastre
        sunt temporar indisponibile din cauza lucrărilor de întreținere.
        Utilizăm datele dumneavoastră cu caracter personal și datele de
        înregistrare pentru a vă crea și gestiona contul în cadrul aplicației
        Cultivá. Ne rezervăm dreptul de a vă dezactiva contul în cazul în care
        suspectăm că utilizați Aplicația noastră pentru a comite acte
        frauduloase sau ilegale sau dacă încălcați Termenii și Condițiile
        noastre de utilizare.
      </Typography>

      <Typography variant="body1" paragraph>
        Temeiul juridic pentru serviciul de clienți este art. 6 Alin. 1 lit. f
        Regulamentul 679/2016 al Parlamentului European (pe baza interesului
        nostru legitim de a vă furniza serviciile) și art. 6 Alin. 1 lit. b
        Regulamentul 679/2016 al Parlamentului European (inițierea contractului
        și executarea contractului).
      </Typography>

      <Typography variant="h6" component="h3" gutterBottom>
        6.3 Activarea aplicației Cultivá
      </Typography>

      <Typography variant="h6" component="h4" gutterBottom>
        Informații despre locație
      </Typography>

      <Typography variant="body1" paragraph>
        Colectăm și procesăm informații despre locație, conform acordului
        manifestat prin Acceptarea Termenilor și Condițiilor sau/și utilizarea
        Aplicației. Colectăm și procesăm informațiile despre locație și traseul
        parcurs de un anumit utilaj agricol într-un anumit interval de timp,
        pentru a putea furniza în cadrul aplicației informații legate de
        utilizarea utilajelor agricole, arealul în care acestea au fost
        utilizate, timpul în care acestea au fost utilizate s.a. referitoare la
        serviciile furnizate în cadrul aplicației.
      </Typography>

      <Typography variant="body1" paragraph>
        Temeiul juridic pentru prelucrarea informațiilor despre locație este
        art. 6 Alin. 1 lit. b Regulamentul 679/2016 al Parlamentului European
        (inițierea contractului și îndeplinirea contractului).
      </Typography>

      <Typography variant="h6" component="h3" gutterBottom>
        6.4 Procesarea plăților
      </Typography>

      <Typography variant="body1" paragraph>
        Dacă vă înregistrați ca utilizator aplicație, datele cardului de
        debit/credit și datele altor metode de plată vor fi transmise de către
        procesatorul de plăți către furnizorul de procesare a plăților și
        prelucrate de acesta din urmă pentru a executa plățile pentru serviciile
        livrate către dumneavoastră prin intermediul Aplicației. S.C. Green Rent
        SRL permite să transfere detaliile cardului de credit sau datele altor
        metode de plată pe care le-ați înregistrat către alți procesatori de
        plăți, dacă va fi cazul. S.C. Green Rent SRL și Cultivá (numele
        aplicației) nu colectează informații despre cardul de debit/credit.
      </Typography>

      <Typography variant="body1" paragraph>
        Temeiul juridic pentru procesarea plăților este art. 6 Alin. 1 lit. b
        Regulamentul 679/2016 al Parlamentului European (inițierea contractului
        și executarea contractului).
      </Typography>

      <Typography variant="h6" component="h3" gutterBottom>
        6.5 Marketing
      </Typography>

      <Typography variant="body1" paragraph>
        S.C. Green Rent SRL vă poate folosi informațiile de contact, respectiv
        adresa de e-mail și numărul de telefon pentru a vă trimite informații
        generale despre noutățile aplicației.
      </Typography>

      <Typography variant="body1" paragraph>
        De asemenea, vă putem informa despre promoțiile, ofertele, noutățile și
        produsele disponibile, în cazul în care v-ați dat consimțământul cu
        privire la astfel de notificări. Vă puteți dezabona de la aceste
        notificări în orice moment. Cu toate acestea, retragerea
        consimțământului nu va afecta legalitatea prelucrării efectuate
        anterior.
      </Typography>

      <Typography variant="body1" paragraph>
        În unele cazuri, ne putem baza activitățile de marketing pe interesul
        nostru legitim de a promova cele mai recente oferte. În același timp,
        luăm toate măsurile necesare pentru a ne asigura că toate drepturile și
        interesele dumneavoastră sunt respectate. Ne puteți cere oricând să nu
        vă mai contactăm în scopuri de marketing. Vă rugăm să consultați
        detaliile noastre de contact din secțiunea 11 a acestui document.
      </Typography>

      <Typography variant="body1" paragraph>
        Temeiul juridic pentru prelucrarea în scopuri de marketing este art. 6
        Alin. 1 lit. A din Regulamentul 679/2016 al Parlamentului European
        (consimțământul persoanei vizate) și art. 6 Alin. 1 lit. f Regulamentul
        679/2016 al Parlamentului European (pe baza interesului nostru legitim
        de a ne promova activitatea).
      </Typography>

      <Typography variant="h6" component="h3" gutterBottom>
        6.6 Compilarea datelor administrative și statistice
      </Typography>

      <Typography variant="body1" paragraph>
        S.C. Green Rent SRL utilizează datele dumneavoastră cu caracter personal
        într-o formă anonimă și agregată pentru a monitoriza exact ce funcții
        ale serviciului sunt utilizate cel mai mult, pentru a analiza tiparele
        de utilizare și pentru a determina unde oferă serviciile potrivite și
        care sunt zonele care ar trebui dezvoltate. Putem furniza aceste
        informații unor terțe părți în scopuri statistice și de analiză a
        industriei. Cu toate acestea, nu divulgăm aceste date către terțe
        persoane.
      </Typography>

      <Typography variant="h6" component="h3" gutterBottom>
        6.7 Cookie-uri / Google Analytics
      </Typography>

      <Typography variant="body1" paragraph>
        S.C. Green Rent SRL și Cultivá folosește cookie-uri. Cookie-urile sunt
        mici fișiere text pe care un site web le plasează pe computer sau pe
        dispozitivul mobil atunci când vizitați pentru prima dată o pagină sau
        un site web. Cookie-urile ne ajută să vă recunoaștem dispozitivul atunci
        când vizitați site-ul web. Cookie-urile au funcții diferite. Cu ajutorul
        lor putem, de exemplu, să reținem interesele dvs. Cookie-urile ne pot
        ajuta să analizăm cât de bine funcționează site-ul nostru web sau să ne
        adaptăm conținutul astfel încât informațiile relevante să vă fie
        furnizate. Numai ID-ul sesiunii este salvat și transmis în cookie-uri.
      </Typography>

      <Typography variant="body1" paragraph>
        Majoritatea browserelor pot fi setate astfel încât să nu mai accepte
        cookie-uri sau veți fi notificat atunci când primiți un cookie. În
        majoritatea browserelor veți găsi informații despre posibilele
        modificări ale setărilor browserului sub elementul de meniu "Ajutor".
        Dacă decideți să dezactivați și/sau să ștergeți cookie-urile în viitor,
        trebuie să luați în considerare faptul că unele funcții ale aplicației
        Cultivá nu vor mai fi disponibile pentru dvs.
      </Typography>

      <Typography variant="body1" paragraph>
        <strong>
          Tipuri de cookie-uri utilizate de site-ul și/sau aplicația noastră
        </strong>
      </Typography>

      <Typography variant="body1" paragraph>
        Acest tip de cookie-uri este necesar pentru funcționarea adecvată a
        site-ului și nu poate fi blocat de administratorii noștri web.
        Cookie-urile esențiale sunt utilizate în general în funcție de
        activitatea de pe site, de exemplu: utilizarea opțiunilor de
        confidențialitate, conectarea sau completarea formularelor. Puteți bloca
        aceste cookie-uri, dar în acest caz site-ul web nu va mai funcționa
        corect.
      </Typography>

      <Typography variant="body1" paragraph>
        Acest tip de cookie-uri ne permite să evaluăm numărul de vizite la Site
        și să procesăm date despre sursele de trafic, pentru a îmbunătăți
        funcționalitatea site-ului. Ne ajută să vedem care sunt cele mai
        populare secțiuni și cum navighează vizitatorii pe site-ul nostru. Dacă
        blocați aceste cookie-uri, nu vom mai putea procesa informații despre
        modul în care este utilizat site-ul și nu vom mai putea supraveghea
        performanța acestuia.
      </Typography>

      <Typography variant="body1" paragraph>
        Acest tip de cookie-uri ne ajută să îmbunătățim eficiența activităților
        de marketing și să ne adaptăm acțiunile la nevoile și preferințele
        dumneavoastră pentru a ne aminti ce alegeri ați făcut pe site.
      </Typography>

      <Typography variant="body1" paragraph>
        Pentru a ne promova bunurile și serviciile, putem afișa anunțuri pe alte
        site-uri web. Acest tip de cookie-uri este utilizat pentru a face
        reclamele noastre relevante și personalizate în funcție de preferințele
        dumneavoastră. Cookie-urile împiedică, de asemenea, repetarea aceleiași
        reclame. Aceste cookie-uri sunt utilizate pentru a face cunoscute
        activitățile noastre.
      </Typography>

      <Typography variant="body1" paragraph>
        Temeiul juridic pentru prelucrarea datelor cu caracter personal folosind
        cookie-uri este art. 6 Alin. 1 lit. f Regulamentul 679/2016 al
        Parlamentului European, (interesul legitim) și art. 6 Alin. 1 lit. A din
        Regulamentul 679/2016 al Parlamentului European (consimțământul
        persoanei vizate).
      </Typography>

      <Typography variant="h6" component="h3" gutterBottom>
        6.8 Temeiul juridic pentru prelucrarea datelor cu caracter personal
      </Typography>

      <Typography variant="body1" paragraph>
        În măsura în care obținem consimțământul persoanei vizate pentru
        prelucrarea datelor cu caracter personal, articolul 6 alineatul (1)
        litera (a) din Regulamentul 679/2016 al Parlamentului European (GDPR)
        reprezintă temeiul juridic pentru prelucrarea datelor cu caracter
        personal.
      </Typography>

      <Typography variant="body1" paragraph>
        Atunci când prelucrarea datelor cu caracter personal, care este necesară
        pentru executarea unui contract la care persoana vizată este parte, art.
        6 alin. 1 lit.b Regulamentul 679/2016 al Parlamentului European este
        temeiul juridic. Acest lucru se aplică, de asemenea, operațiunilor de
        prelucrare care sunt necesare pentru a efectua negocierile
        precontractuale.
      </Typography>

      <Typography variant="body1" paragraph>
        În măsura în care prelucrarea datelor cu caracter personal este necesară
        pentru îndeplinirea unei obligații legale la care este supusă compania
        noastră, art. 6 alin. 1 lit. c din Regulamentul 679/2016 al
        Parlamentului European este temeiul juridic.
      </Typography>

      <Typography variant="body1" paragraph>
        În cazul în care interesele vitale ale persoanei vizate sau ale altei
        persoane fizice necesită prelucrarea datelor cu caracter personal, art.
        6 alin. 1 lit. d Regulamentul 679/2016 al Parlamentului European
        servește drept temei juridic.
      </Typography>

      <Typography variant="body1" paragraph>
        Dacă prelucrarea este necesară pentru a proteja interesele legitime ale
        companiei noastre sau ale unei terțe părți și dacă interesele,
        drepturile și libertățile fundamentale ale persoanei vizate nu
        prevalează asupra interesului nostru, art. 6 (1) lit. f din Regulamentul
        679/2016 al Parlamentului European este temeiul juridic pentru
        prelucrare.
      </Typography>

      <Typography variant="h6" component="h2" gutterBottom>
        6.9 Nici o obligație de a furniza date cu caracter personal
      </Typography>

      <Typography variant="body1" paragraph>
        Nu este obligatoriu să furnizați datele dumneavoastră cu caracter
        personal. Cu toate acestea, fără datele dumneavoastră cu caracter
        personal, nu vă putem furniza serviciile noastre sau le putem furniza
        doar parțial, în funcție de datele cu caracter personal pe care refuzați
        să le furnizați.
      </Typography>

      <Typography variant="body1" paragraph>
        Nu mai departe, nu este obligatoriu ca în cadrul utilizării aplicației
        mobile, să aveți activate notificările push. Cu toate acestea,
        notificările push sunt necesare și esențiale pentru ca Cultivá. (numele
        Aplicației ) să vă poată comunica informații despre actualizările
        Aplicației, ofertele și produsele disponibile sau actualizate.
      </Typography>

      <Typography variant="h6" component="h2" gutterBottom>
        8. Destinatarii datelor/transmiterea datelor în țări terțe
      </Typography>

      <Typography variant="body1" paragraph>
        Putem utiliza diverse terțe părți și companii externe pentru a ne
        permite sau furniza serviciile, pentru a procesa plăți, pentru a oferi
        asistență pentru clienți, pentru a furniza informații despre locație,
        pentru a interpreta și organiza informațiile transmise cu privire la
        locație, pentru a furniza servicii de marketing și pentru a furniza
        servicii legate de site-uri web (inclusiv servicii de întreținere,
        gestionarea bazelor de date, analiză web și îmbunătățirea funcțiilor
        site-ului web) sau pentru a ne sprijini în analizarea utilizării
        serviciilor noastre. Aceste terțe părți au acces la datele dumneavoastră
        cu caracter personal și le prelucrează pentru furnizarea serviciilor
        contractate de noi.
      </Typography>

      <Typography variant="body1" paragraph>
        În acest scop, datele dumneavoastră cu caracter personal vor fi
        transferate și prelucrate în alte țări din afara Uniunii Europene,
        inclusiv în țări (cum ar fi Statele Unite ale Americii) care nu au
        adoptat legi privind protecția datelor comparabile cu Uniunea Europeană
        și România. Astfel, sunteți de acord cu transferul și prelucrarea
        datelor dumneavoastră cu caracter personal către astfel de țări, în
        special către Statele Unite. Transferăm date cu caracter personal în
        locații din țări din afara Uniunii Europene (așa-numitele țări terțe) în
        măsura în care acest transfer este impus de lege (de exemplu, cerințele
        de raportare fiscală), persoana vizată și-a dat consimțământul,
        transferul este permis în interesul legitim în temeiul legislației
        privind protecția datelor interesele persoanei vizate nu prevalează
        asupra celor ale Operatorului care intră în conflict cu acestea, sau
        dacă acest transfer este necesar să vă furnizăm serviciile noastre.
      </Typography>

      <Typography variant="body1" paragraph>
        Serviciile pe care trebuie să vi le oferim sunt PCI (payment card
        industry) compliant pentru procesarea plăților, furnizare de hosting în
        special pentru găzduirea datelor și aplicațiilor, Helpdesk/Support,
        furnizori de Business Intelligence, serviciu de comunicații și aplicații
        business pentru comunicarea prin e-mail.
      </Typography>

      <Typography variant="body1" paragraph>
        Pentru a vă proteja datele cu caracter personal, am încheiat clauze
        contractuale standard ale UE cu destinatarii datelor dumneavoastră din
        străinătate. Nu mai departe, furnizorii noștri de servicii sunt ținuți
        de obligații de confidențialitate și protecția datelor.
      </Typography>

      <Typography variant="body1" paragraph>
        S.C. Green Rent SRL dezvăluie datele dumneavoastră cu caracter personal
        în măsura în care acest lucru este necesar din punct de vedere legal sau
        necesar pentru stabilirea, exercitarea și apărarea drepturilor și în
        cadrul procedurilor legale și, în caz de urgență, a datelor referitoare
        la securitate.
      </Typography>

      <Typography variant="h6" component="h2" gutterBottom>
        9. Drepturile dumneavoastră privind protecția datelor
      </Typography>

      <Typography variant="body1" paragraph>
        În calitate de persoană vizată, aveți dreptul la informații despre
        contul dumneavoastră personal. Vă puteți exercita drepturile în temeiul
        Regulamentului 679/2016 al Parlamentului European, cum ar fi: a. de a
        pune în aplicare dreptul de a solicita corectarea sau ștergerea datelor
        dumneavoastră cu caracter personal sau de a vă opune prelucrării datelor
        dumneavoastră cu caracter personal în orice moment, trimițându-ne un
        e-mail sau contactându-ne la adresa indicată în secțiunea de mai jos.
        Dacă doriți să formulați o solicitare, va trebui să anexați o copie a
        cărții de identitate sau a pașaportului pentru o identificare fără
        dubii.
      </Typography>

      <Typography variant="body1" paragraph>
        În conformitate cu articolul 15 din Regulamentul 679/2016 al
        Parlamentului European, fiecare persoană vizată are dreptul la
        informare. În conformitate cu articolul 16 din Regulamentul 679/2016 al
        Parlamentului European, persoana vizată poate solicita actualizarea
        datelor cu caracter personal incorecte. În conformitate cu articolul 17
        din Regulamentul 679/2016 al Parlamentului European, persoana vizată are
        dreptul la ștergere (dreptul de a fi uitat) sau, în conformitate cu
        articolul 18, dreptul de a restricționa prelucrarea. De asemenea,
        persoana în cauză se poate opune prelucrării datelor cu caracter
        personal care o privesc în condițiile articolului 21 din Regulamentul
        679/2016 al Parlamentului European. În conformitate cu articolul 20 din
        Regulamentul 679/2016 al Parlamentului European, persoana vizată are
        dreptul la portabilitatea datelor. Pentru exercitarea acestor drepturi,
        ne puteți contacta la S.C. Green Rent SRL, sediul în municipiul Iași,
        Str. Dimitrie Ralet, nr 1, bl. C1, parter, Cam. 2, înregistrată la
        Oficiul Registrului Comerțului sub nr. J22/2947/2019, cod unic de
        înregistrare 41639487, sau la adresa de e-mail:{" "}
        <a href="mailto:client@cultivapp.ro">client@cultivapp.ro</a> sau în
        secțiunea de contact de pe site-ul nostru.
      </Typography>

      <Typography variant="body1" paragraph>
        Aveți dreptul de a vă retrage consimțământul în temeiul GDPR în orice
        moment. Retragerea consimțământului nu afectează legalitatea prelucrării
        pe baza consimțământului înainte de retragerea acestuia. În plus, aveți
        dreptul de a depune o plângere la autoritatea competentă de supraveghere
        a protecției datelor (ANSPDCP).
      </Typography>

      <Typography variant="body1" paragraph>
        S.C. Green Rent SRL își rezervă dreptul de a percepe taxe rezonabile de
        procesare pentru furnizarea de informații relevante în cazul unor cereri
        nefondate sau excesive.
      </Typography>

      <Typography variant="body1" paragraph>
        De asemenea, vă puteți modifica datele cu caracter personal prin
        intermediul contului dumneavoastră de utilizator și puteți revoca
        consimțământul pe care l-ați dat.
      </Typography>

      <Typography variant="h6" component="h2" gutterBottom>
        10. Luarea de decizii automatizate în cazuri individuale, inclusiv
        crearea de profiluri
      </Typography>

      <Typography variant="body1" paragraph>
        În legătură cu furnizarea serviciilor noastre, nu veți fi supus unei
        decizii bazate pe prelucrarea automată în conformitate cu articolul 22
        din Regulamentul 679/2016 al Parlamentului European. Dacă utilizăm
        astfel de proceduri în cazuri individuale, veți fi informat cu privire
        la acestea și la drepturile dumneavoastră asociate în cadrul cerințelor
        legale, inclusiv dreptul de a vă opune și de a solicita intervenția
        umană.
      </Typography>

      <Typography variant="body1" paragraph>
        Unele dintre datele dumneavoastră sunt prelucrate automat pentru
        evaluarea anumitor aspecte personale (profilare).
      </Typography>

      <Typography variant="h6" component="h2" gutterBottom>
        11. Stocarea datelor și dreptul de a fi uitat
      </Typography>

      <Typography variant="body1" paragraph>
        Cu excepția cazului în care se prevede altfel în această Politică de
        confidențialitate, stocăm datele dumneavoastră până când vă ștergeți
        contul Cultivá. Dacă doriți să vă ștergeți contul sau să trimiteți o
        cerere pentru ca noi să nu vă mai utilizăm datele în viitor pentru a vă
        furniza servicii, vă rugăm să ne contactați la{" "}
        <a href="mailto:client@cultivapp.ro">client@cultivapp.ro</a>.
      </Typography>

      <Typography variant="body1" paragraph>
        În cazul în care datele nu sunt necesare pentru a respecta obligațiile
        legale sau pentru a soluționa dispute sau litigii, vă vom șterge datele
        cu caracter personal după ștergerea contului dvs.
      </Typography>

      <Typography variant="body1" paragraph>
        Cu toate acestea, datele cu caracter personal utilizate pentru răspunsul
        la cererea formulată de dumneavoastră vor fi prelucrate pe o perioadă
        determinată de timp, în interesul legitim al Operatorului de a demonstra
        îndeplinirea, confirmarea și răspunsul la solicitările formulate de
        dumneavoastră.
      </Typography>

      <Typography variant="h6" component="h2" gutterBottom>
        12. Informații despre dreptul dumneavoastră de a vă opune în
        conformitate cu articolul 21 din GDPR
      </Typography>

      <Typography variant="body1" paragraph>
        <strong>Dreptul de a vă opune, într-o situație particulară.</strong>
      </Typography>

      <Typography variant="body1" paragraph>
        Aveți dreptul, din motive care decurg din situația dumneavoastră
        particulară, în orice moment, împotriva prelucrării datelor
        dumneavoastră cu caracter personal, care se bazează pe articolul 6
        alineatul (1) lit. e (prelucrarea datelor în interes public) sau lit. f
        (prelucrarea datelor pe baza interesului legitim), să vă opuneți la
        prelucrarea datelor; acest lucru se aplică și creării de profiluri pe
        baza acestor prevederi.
      </Typography>

      <Typography variant="body1" paragraph>
        Dacă formulați o opoziție la prelucrare, nu vă vom mai prelucra datele
        cu caracter personal. Un refuz sau o obiecție se aplică numai dacă putem
        demonstra motive legitime convingătoare pentru prelucrare care depășesc
        interesele, drepturile și libertățile dumneavoastră sau dacă prelucrarea
        servește la afirmarea, exercitarea sau apărarea revendicărilor legale.
      </Typography>

      <Typography variant="body1" paragraph>
        Opoziția poate fi făcută informal cu subiectul "obiecție" în care se
        menționează numele, adresa și data nașterii și trebuie adresată
        informațiilor de contact furnizate în secțiunea 11.
      </Typography>

      <Typography variant="h6" component="h2" gutterBottom>
        13. Măsuri de siguranță
      </Typography>

      <Typography variant="body1" paragraph>
        S.C. Green Rent SRL a luat măsuri de securitate tehnice și
        organizaționale adecvate împotriva pierderii sau prelucrării ilegale a
        datelor dumneavoastră cu caracter personal. În acest scop, datele
        dumneavoastră cu caracter personal vor fi stocate în siguranță în baza
        noastră de date, respectiv în baza de date Mongodb Cloud. Utilizăm
        măsuri de securitate standard în domeniu, adecvate din punct de vedere
        economic, cum ar fi firewall-uri și SSL (Secure Socket Layers) și
        securizăm fizic locațiile în care sunt stocate datele.
      </Typography>

      <Typography variant="body1" paragraph>
        Oricât de eficiente ar fi măsurile noastre de securitate, niciun sistem
        de securitate nu este infailibil. Nu putem garanta securitatea bazei
        noastre de date și nici nu putem garanta că informațiile pe care le
        furnizați nu vor fi interceptate atunci când ne sunt transmise prin
        Internet. Transferul datelor dumneavoastră către S.C. Green Rent SRL și
        aplicațiile Cultivá este efectuat întotdeauna pe propriul risc. Vă
        recomandăm să nu dezvăluiți parola nimănui.
      </Typography>

      <Typography variant="h6" component="h2" gutterBottom>
        14. Modificări ale acestei Politici de Confidențialitate
      </Typography>

      <Typography variant="body1" paragraph>
        Putem actualiza oricând această Politică de Confidențialitate pentru a
        reflecta modificările în practicile noastre de prelucrare a
        informațiilor și a datelor. În cazul unor modificări semnificative, vă
        vom informa înainte ca modificarea corespunzătoare să intre în vigoare
        prin e-mail și printr-un mesaj pe site-ul nostru web, solicitându-vă să
        confirmați citirea și primirea. Continuând să utilizați site-ul web
        și/sau aplicația și/sau serviciile, confirmați că ați luat la cunoștință
        de și sunteți de acord cu Politica de confidențialitate actualizată. Vă
        recomandăm să vizitați această pagină în mod regulat pentru a afla
        despre cele mai recente inovații în măsurile noastre de protecție a
        datelor.
      </Typography>

      <Typography variant="h6" component="h2" gutterBottom>
        15. Informații de contact
      </Typography>

      <Typography variant="body1" paragraph>
        Ne puteți contacta:
      </Typography>

      <Typography variant="body1" paragraph>
        S.C. Green Rent SRL cu sediul în municipiul Iași, Str. Dimitrie Ralet,
        nr 1, bl. C1, parter, Cam. 2, înregistrată la Oficiul Registrului
        Comerțului sub nr. J22/2947/2019, cod unic de înregistrare 41639487
      </Typography>

      <Typography variant="body1" paragraph>
        E-mail: <a href="mailto:client@cultivapp.ro">client@cultivapp.ro</a>
      </Typography>

      <Typography variant="body1" paragraph>
        Ultima actualizare: August 2024.
      </Typography>
    </Box>
  );
};
