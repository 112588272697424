import React, { ReactElement, useState } from "react";
import {
  TextField,
  Grid,
  Typography,
  Box,
  Button,
  Card,
  CardContent,
  Avatar,
  IconButton,
  Modal,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faStar,
  faAngleLeft,
  faAngleRight,
} from "@fortawesome/free-solid-svg-icons";
import user1Pic from "../assets/images/user1.png";
import user2Pic from "../assets/images/user2.png";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

interface reviewCardObject {
  image: string;
  author: string;
  description: string;
  rating: number;
}

const CardReview = ({
  image,
  author,
  description,
  rating,
}: reviewCardObject): ReactElement => {
  const validRating = Math.max(0, Math.min(5, rating)); // Validare pentru rating

  return (
    <Card
      sx={{
        display: "flex",
        alignItems: "center",
        padding: "16px",
        backgroundColor: "#f9f9f9",
        width: { sx: "auto", lg: "530px", md: "400px" },
        height: { sx: "auto", lg: "160px" },
        marginTop: "10px",
        marginBottom: "20px",
        marginRight: "10px",
        marginLeft: "10px",
        borderRadius: "10px",
        boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
      }}
    >
      <CardContent sx={{ flex: 1 }}>
        <Typography
          className="text-grey"
          sx={{ fontFamily: "Manrope", fontSize: "12", textAlign: "justify" }}
        >
          {description}
        </Typography>
        <Box display="flex" alignItems="center" marginTop="10px" sx={{}}>
          <Typography
            className="text-black-b"
            sx={{ fontSize: "16px", fontFamily: "Manrope" }}
          >
            {author}
          </Typography>
          <Box display="flex" marginLeft="8px">
            {Array(validRating)
              .fill(0)
              .map((_, i) => (
                <FontAwesomeIcon
                  key={i}
                  icon={faStar}
                  style={{ color: "#FFD700" }}
                />
              ))}
          </Box>
        </Box>
      </CardContent>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{ padding: "16px", mr: 3 }}
      >
        <Avatar
          alt={author}
          src={image}
          sx={{
            width: 80,
            height: 80,
            backgroundColor: "#fff",
            border: "2px dashed #FFD700",
          }}
        />
        <Box
          sx={{
            position: "relative",
            width: 20,
            height: 20,
            backgroundColor: "#4CAF50",
            borderRadius: "50%",
            marginLeft: "-50px",
            marginTop: "70px",
          }}
        />
      </Box>
    </Card>
  );
};

const RoundedTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    borderRadius: "15px",
  },
  "& .MuiInputLabel-root": {
    borderRadius: "15px",
  },
});

interface Errors {
  name?: boolean;
  rating?: boolean;
  review?: boolean;
}

export const ReviewComponent = (props: { id: string }): ReactElement | null => {
  const reviews: reviewCardObject[] = [
    {
      image: user1Pic,
      author: "Popa George",
      description: "“Words made of honey, I like it” . ",
      rating: 5,
    },
    // {
    //   image: user2Pic,
    //   author: "Emily Tolbert",
    //   description:
    //     "There are many variations of passages of available but the majority have suffered alteration in some form by injected humor or random word which don't look even. ",
    //   rating: 3,
    // },

    // {
    //   image: user2Pic,
    //   author: "Vasile Atomei",
    //   description:
    //     "There are many variations of passages of available but the majority have suffered alteration in some form by injected humor or random word which don't look even. ",
    //   rating: 2,
    // },
    // {
    //   image: user1Pic,
    //   author: "Rares Darabana",
    //   description: "“Words made of honey, I like it” . ",
    //   rating: 5,
    // },
  ];

  const [name, setName] = useState<string>("");
  const [rating, setRating] = useState<number>(0);
  const [review, setReview] = useState<string>("");
  const [errors, setErrors] = useState<Errors>({});
  const [currentReviewIndex, setCurrentReviewIndex] = useState(0);
  const [isExpanded, setIsExpanded] = useState(false);

  const handleSubmit = () => {
    let formErrors: Errors = {};
    if (!name) formErrors.name = true;
    if (!rating) formErrors.rating = true;
    if (!review) formErrors.review = true;

    setErrors(formErrors);

    if (Object.keys(formErrors).length === 0) {
      console.log("Name:", name);
      console.log("Rating:", rating);
      console.log("Review:", review);
    }
  };

  const handleButtonClick = () => {
    setIsExpanded(!isExpanded);
  };

  const handlePrevious = () => {
    setCurrentReviewIndex((prevIndex) =>
      prevIndex === 0 ? reviews.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentReviewIndex((prevIndex) =>
      prevIndex === reviews.length - 1 ? 0 : prevIndex + 1
    );
  };

  const navigate = useNavigate();

  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "auto",
          background: "#F8F7F0",
          padding: 2,
        }}
      >
        <Grid
          id={props.id}
          container
          justifyContent="center"
          spacing={2}
          sx={{ mt: 10, mb: 10 }}
        >
          <Grid
            item
            sm={12}
            md={4}
            lg={3.2}
            alignContent={{ xs: "center", md: null }}
          >
            <Typography
              className="typography-orange"
              sx={{
                fontSize: "24px",
                textAlign: { xs: "center", md: "left" },
                mt: { xs: -10, md: 0 },
                mb: { xs: 2, md: 0 },
              }}
            >
              Testimonialele adresate noua
            </Typography>
            <Typography
              className="text-black-b"
              sx={{
                mb: 2,
                fontSize: "28px",
                lineHeight: "1",
                fontFamily: "Manrope",
                textAlign: { xs: "center", md: "left" },
              }}
            >
              Ce se spune despre Cultiva?
            </Typography>
            <Typography
              className="text-grey"
              sx={{
                lineHeight: "2",
                fontFamily: "Manrope",
                textAlign: { xs: "center", md: "justify" },
              }}
            >
              Cultivá, consultantul AI în administrarea fermei, redă controlul
              fermierilor, permițându-le să se concentreze pe dezvoltarea
              fermei. Fără să mai piardă timp investigând activitățile agricole
              sau înregistrând bonuri de lucru și rapoarte de activitate.
            </Typography>
            <Grid
              spacing={4}
              display={"flex"}
              justifyContent={{ xs: "center", md: "left" }}
              sx={{ mt: { xs: 2, md: 0 } }}
            >
              <Button
                className="button-green mt-2"
                onClick={handleButtonClick}
                sx={{ fontFamily: "Manrope" }}
              >
                {isExpanded ? "închide testimonial" : "Scrie un testimonial"}
              </Button>
            </Grid>
          </Grid>
          <Grid
            sx={{
              mt: 2,
            }}
          >
            <Box
              sx={{
                mt: 2,
                position: "relative",
                display: "flex",
                justifyContent: "center",
                ml: 2,
              }}
            >
              {/* Afișează doar review-ul curent */}
              <CardReview
                image={reviews[currentReviewIndex].image}
                author={reviews[currentReviewIndex].author}
                description={reviews[currentReviewIndex].description}
                rating={reviews[currentReviewIndex].rating}
              />
            </Box>

            <Box
              sx={{
                justifyContent: "center",
                display: "flex",
                marginTop: "10px",
                mb: { xs: -7, md: 0 },
              }}
            >
              <IconButton
                sx={{
                  textAlign: "center",
                  width: "40px",
                  height: "40px",
                  backgroundColor: "#fff",
                  marginRight: "10px",
                  borderRadius: "20px",
                  boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                }}
                onClick={handlePrevious}
              >
                <FontAwesomeIcon icon={faAngleLeft} />
              </IconButton>
              <IconButton
                sx={{
                  textAlign: "center",
                  width: "40px",
                  height: "40px",
                  backgroundColor: "#fff",
                  borderRadius: "20px",
                  boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                }}
                onClick={handleNext}
              >
                <FontAwesomeIcon icon={faAngleRight} />
              </IconButton>
            </Box>
          </Grid>

          {/* formular testimonial într-un modal */}
          <Modal open={isExpanded} onClose={handleButtonClick}>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: { xs: 350, sm: 450, lg: 500 },
                bgcolor: "background.paper",
                boxShadow: 24,
                p: 2,
                borderRadius: 2,
              }}
            >
              <Typography
                className="text-black-b"
                sx={{ fontSize: "20px", textAlign: "center", mt: 5 }}
              >
                Tu ce părere ai despre noi?
              </Typography>
              <Box
                sx={{
                  borderRadius: "20px",
                  padding: "20px",
                  background: "white",
                  alignContent: "center",
                  mt: 2,
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <RoundedTextField
                      label="Cine ești?"
                      fullWidth
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      error={!!errors.name}
                      helperText={errors.name && "Acest câmp este obligatoriu"}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        color: errors.rating ? "red" : "inherit",
                      }}
                    >
                      {[1, 2, 3, 4, 5].map((value) => (
                        <FontAwesomeIcon
                          key={value}
                          icon={faStar}
                          style={{
                            cursor: "pointer",
                            color: value <= rating ? "gold" : "grey",
                          }}
                          onClick={() => setRating(value)}
                        />
                      ))}
                      {errors.rating && (
                        <Typography
                          variant="body2"
                          sx={{ color: "red", ml: 1 }}
                        >
                          Trebuie să dai un rating
                        </Typography>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <RoundedTextField
                      label="Părerea ta contează"
                      fullWidth
                      multiline
                      rows={4}
                      value={review}
                      onChange={(e) => setReview(e.target.value)}
                      error={!!errors.review}
                      helperText={
                        errors.review && "Acest câmp este obligatoriu"
                      }
                    />
                  </Grid>
                  <Grid item xs={12} className="d-flex justify-content-center">
                    <Button
                      className="button-green w-50"
                      onClick={handleSubmit}
                    >
                      Gata!
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Modal>
        </Grid>
      </Box>
    </>
  );
};
