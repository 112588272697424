import React, { ReactElement } from "react";
import { HeaderPage } from "../Components/Header";
import {
  Grid,
  Typography,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
} from "@mui/material";
import { Footer } from "../Components/Footer";
import { NavBar } from "../Components/NavBar";
import { FeedBackComponent } from "../Components/FeddbackComponent";

export const Feedback = (): ReactElement | null => {
  return (
    <div style={{ background: "#F8F7F0" }}>
      <HeaderPage />
      <NavBar />
      <Box>
        <Grid container className="d-flex  w-100 ">
          <Box
            className="d-flex justify-content-center align-items-center"
            sx={{ width: "100%", height: "300px", background: "#4BAF47" }}
          >
            <Grid item textAlign="center">
              <Typography
                sx={{
                  color: "#EEC044",
                  fontFamily: "Covered By Your Grace",
                  fontSize: "40px",
                }}
              >
                Feedback
              </Typography>
              <Typography
                className="text-white"
                sx={{ fontSize: { md: "45px", xs: "20px" } }}
              >
                Părerea ta contează pentru a oferi servicii de calitate
              </Typography>
            </Grid>
          </Box>
        </Grid>

        <FeedBackComponent />
      </Box>
      <Footer />
    </div>
  );
};
