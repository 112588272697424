import React, { ReactElement, useEffect, useReducer, useState } from "react";
import { NavigationHeader } from "../Components/Navigation";
import { Grid } from "@mui/material";
import { BaseMap, T_MapData, T_MapMarker } from "../Components/Map";
import * as turf from "@turf/turf";
import {
  T_LatLng,
  T_Path,
  getConsumptionForPolygon,
  getLastPosition,
  getMachineryPath,
  getMachinerys,
} from "../Requests/machinery";
import { T_farm, getFarms } from "../Requests/farms";
import { store } from "../store/main";
import { setActiveFarm, setFarms } from "../store/slicers/farm";
import {
  setMachinaryPosition,
  setMachineries,
} from "../store/slicers/machinary";
import { Modal, T_Modal } from "../Components/Modal";
import moment, { Moment } from "moment";

export interface T_Point {
  lat: number;
  lng: number;
}

export interface T_form {
  start: Moment;
  end: Moment;
  machinaryId?: string;
}

export const Map = (): ReactElement | null => {
  const [_, forceUpdate] = useReducer((x) => x + 1, 0);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [machinaryPath, setMachinaryPath] = useState<T_Path>();
  const [modalTarget, setModalTarget] = useState<T_Modal["target"]>();
  const [form, setForm] = useState<T_form>();

  useEffect(() => {
    getFarms((farms) => {
      store.dispatch(setFarms(farms));
      store.dispatch(setActiveFarm(farms ? farms[0] : null));
      // force update

      // get machinerys for this farm
      if (farms && farms.length) {
        getMachinerys(farms[0]._id, (machinaries) => {
          store.dispatch(setMachineries(machinaries));
          forceUpdate();
          // get last locations for each machinary
          updateLastPositions();
        });
      }
    });

    //getMachinerys((machinersDb) => {});
  }, []);

  const updateLastPositions = () => {
    var count = 0;
    const state = store.getState();
    const machinaries = state.machinaries;
    machinaries?.forEach((machinary) => {
      getLastPosition(machinary.deviceId, (position) => {
        store.dispatch(setMachinaryPosition(position));
        count++;
        if (count === machinaries.length) {
          forceUpdate();
        }
      });
    });
  };

  const handleModalClose = () => {
    setAnchorEl(null);

    setModalTarget({
      type: null,
    });
  };

  const handleMarkerClick = (
    marker: T_MapMarker,
    target: HTMLButtonElement
  ) => {
    setAnchorEl(target);
    setModalTarget({
      _id: "",
      type: "marker",
    });
    // get track for today
    const state = store.getState();
    const machinary = state.machinaries.filter(
      (machinary) => machinary._id === marker._id
    )[0];

    if (machinary && (machinary._id !== form?.machinaryId || !machinaryPath)) {
      // get today track

      const start = moment().startOf("day");
      const end = moment();

      // update form
      let f = { ...form };
      f.start = start;
      f.end = end;
      f.machinaryId = machinary._id;
      setForm(f);

      setMachinaryPath(null);
      getMachineryPath(
        {
          deviceId: machinary.deviceId,
          start,
          end,
        },
        (path) => {
          setMachinaryPath(path);
          setModalTarget({
            _id: machinary._id,
            type: "marker",
            machinaryPath: path,
          });
        }
      );
    }
  };

  const startMesure = (machinaryId: string) => {
    let target = { ...modalTarget };
    target.type = "mesureEdit";
    setModalTarget(target);
  };

  const updateModalTarget = (
    modalTargetNew: T_Modal["target"],
    anchorElNew?: HTMLButtonElement | null
  ) => {
    const modalT = { ...modalTargetNew };
    const anchoE = { ...anchorElNew };
    setModalTarget(modalT);
    if (anchorElNew) {
      setAnchorEl(anchoE);
    }
  };

  const getPathInfoForPolygon = (polygon: T_Point[]) => {
    const state = store.getState();
    const machinary = state.machinaries.filter(
      (machinary) => machinary._id === modalTarget._id
    )[0];
    let points: T_LatLng[] = polygon.map((point) => {
      return { latitude: point.lat, longitude: point.lng };
    });
    points.push(points[0]);
    getConsumptionForPolygon(
      {
        start: form?.start.toISOString(),
        end: form?.end.toISOString(),
        polygon: points,
        deviceId: machinary?.deviceId,
      },
      (data) => {
        // calculate area for this poligon

        const turfPoligon = turf.polygon([
          points.map((point) => [point.longitude, point.latitude]),
        ]);
        const area = turf.area(turfPoligon) / 10000;

        let modalT = { ...modalTarget };
        modalT.trackInfo = { ...data };
        modalT.trackInfo.area = area;
        setModalTarget(modalT);
      }
    );
  };

  const state = store.getState();
  const activeFarm: T_farm = state.farms?.activeFarm;
  const machinaries = state.machinaries;
  const markers: T_MapMarker[] = machinaries
    ?.map((machinery) => {
      if (machinery.lastPositionData) {
        let marker: T_MapMarker = {
          lat: parseFloat(machinery.lastPositionData.latitude),
          lng: parseFloat(machinery.lastPositionData.longitude),
          collectTime: machinery.lastPositionData.time,
          type: "machinary",
          _id: machinery._id,
          name: machinery.name
            ? machinery.name
            : `${machinery.producer} ${machinery.model}`,
        };
        return marker;
      } else {
        return null;
      }
    })
    .filter((marker) => marker);

  const polyline: T_MapData["polyline"] = machinaryPath?.path.map((point) => {
    return {
      lat: point[1],
      lng: point[0],
    };
  });

  return (
    <>
      <NavigationHeader activePage="map" title={activeFarm?.name} />
      <Grid container>
        <Grid
          item
          sx={{
            width: "100%",
            height: window.innerHeight - 64,
          }}
        >
          <BaseMap
            markers={markers}
            polyline={polyline}
            target={modalTarget}
            actions={{
              onClickMarker: handleMarkerClick,
              getPathInfo: getPathInfoForPolygon,
            }}
          />
          <Modal
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleModalClose}
            actions={{
              masure: startMesure,
              closeModal: handleModalClose,
              setModalTarget: setModalTarget,
              setMachinaryPath: setMachinaryPath,
            }}
            target={modalTarget}
          />
        </Grid>
      </Grid>
    </>
  );
};
