import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  colors,
} from "@mui/material";
import React, { ReactElement, useEffect, useState } from "react";
import { Inputs, T_input } from "../Components/Inputs";
import { Buttons, T_button } from "../Components/Buttons";
import { authUser } from "../Requests/auth";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";

export interface T_userLogin {
  username: string;
  password: string;
}

export const Login = (): ReactElement | null => {
  const [form, setForm] = useState<T_userLogin>();

  const navigate = useNavigate();

  const elements: T_input[] = [
    {
      label: "Utilizator",
      value: "username",
      type: "text",
      sx: {
        padding: 0.5,
      },
    },
    {
      label: "Parola",
      value: "password",
      type: "password",
      sx: {
        padding: 0.5,
      },
    },
  ];

  // try to login
  const loginReq = () => {
    authUser(form, (done) => {
      if (done) {
        // navigate to dashboard
        navigate("/map");
      }
    });
  };

  const buttons: T_button[] = [
    {
      action: () => navigate("/"),
      label: "Renunță",
      value: "renunta",
      variant: "contained",
    },
    {
      action: () => loginReq(),
      label: "Intră",
      value: "login",
      variant: "contained",
    },
  ];

  const onChange = (input: T_input, value: any) => {
    const newForm = { ...form };

    Object.assign(newForm, { [input.value]: value });
    setForm(newForm);
  };

  useEffect(() => {
    if (checkLogin()) {
      // hanvigate to map
      navigate("/map");
    }
  });

  return (
    <Box flex={1}>
      <Grid container>
        <Grid xs={12} item>
          <Typography
            textAlign={"center"}
            className="text-primary"
            fontSize={30}
            sx={{
              margin: 2,
            }}
          >
            <FontAwesomeIcon icon={faUser} alignmentBaseline="central" />
          </Typography>
          <Typography
            textAlign={"center"}
            color={"primary"}
            fontSize={20}
            sx={{
              margin: 2,
            }}
          >
            {"Logare"}
          </Typography>
          <Inputs
            sx={{
              flex: 1,
              flexDirection: "column",
              alignContent: "center",
            }}
            values={form ? form : {}}
            inputs={elements}
            onChange={onChange}
          />
          <Grid
            item
            textAlign={"center"}
            style={{
              padding: 10,
            }}
          >
            <Buttons buttons={buttons} />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export const checkLogin = (): boolean => {
  // check if user is loged in or not
  let token = localStorage.getItem("token");
  return Boolean(token);
};
