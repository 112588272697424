import React, { ReactElement, useEffect } from "react";
import { Provider } from "react-redux";
import { store } from "./store/main";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { roRO } from "@mui/material/locale";
import { RouterProvider } from "react-router-dom";
import { router } from "./Router";
import { registerAxiosInterceptors } from "./Requests/axios";
import './assets/styles/style.css';

export const App = (): ReactElement | null => {
  const theme = createTheme(
    {
      palette: {
        primary: {
          main: "#4CBC79",
          contrastText: "#fff",
        },
        secondary: {
          main: "#EEC044",
          contrastText: "#000",
        },
      },
    },
    roRO
  );

  useEffect(() => {
    // on first load register axios interceptors
    registerAxiosInterceptors();
  }, []);

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <RouterProvider router={router} />
      </ThemeProvider>
    </Provider>
  );
};
