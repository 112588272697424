import { createSlice } from "@reduxjs/toolkit";
import { T_machinery } from "../../Requests/machinery";
import { machineryReducers } from "../actions/machinery";

export const machinerySlicer = createSlice({
  name: "machineries",
  initialState: null as T_machinery[],
  reducers: machineryReducers,
});

export const {
  actions: { setMachineries, setMachinaryPosition },
} = machinerySlicer;
